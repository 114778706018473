import AccessDenied from "@/pages/AccessDenied.vue";
import ChangePassword from "@/pages/ChangePassword.vue";
import Home from "@/pages/Home.vue";
import Login from "@/pages/Login.vue";
import LoginCallback from "@/pages/LoginCallback.vue";
import LoginSignout from "@/pages/LoginSignout.vue";
import PageNotFound from "@/pages/PageNotFound.vue";
import PrimeiroAcesso from "@/pages/PrimeiroAcesso.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import acessoPortalRoutes from "./acesso-portal";
import ajudaRoutes from "./ajuda";
import beforeEach from "./before-each";
import cadastrosRoutes from "./cadastros";
import parametrosRoutes from "./parametros";
import perfilRoutes from "./perfil";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    showInSidebar: true,
    meta: {
      auth: true,
      permissions: new Array(),
    },
    children: [
      // ...propostasRoutes,
      ...cadastrosRoutes,
      ...parametrosRoutes,
      ...perfilRoutes,
      ...acessoPortalRoutes,
      ...ajudaRoutes,
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      auth: true,
      permissions: new Array(),
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: LoginCallback,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/login/signout",
    name: "LoginSignout",
    component: LoginSignout,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/recuperar-senha",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/primeiro-acesso",
    name: "PrimeiroAcesso",
    component: PrimeiroAcesso,
    meta: {
      permissions: new Array(),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

beforeEach(router);

/*
    router.beforeEach((to, from, next) => {
        const tokenLocalStorage = window.sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY);
        if (to.path === "/") {
            if (
                tokenLocalStorage &&
                store.state.usuario.token && 
                (store.state.usuario.token === tokenLocalStorage)
            ) {
                return next({ path: "/cadastros/buscar-empresas" });
            } else {
                store.dispatch('ActionSetUsuario');
                return next({ path: "/login" });
            }
        }
        if (to.matched.some((route) => route.name === "Login")) {
            if (
                tokenLocalStorage &&
                store.state.usuario.token && 
                (store.state.usuario.token === tokenLocalStorage)
            ) {
                return next({ path: "/cadastros/buscar-empresas" });
            }
        }
        if (to.matched.some((route) => route.meta.auth == true)) {
            if (store.state.usuario.usuario.primeiroAcesso && tokenLocalStorage) {
                return next({ path: "/primeiro-acesso" });
            }
            if (!tokenLocalStorage  || !store.state.usuario.token || (store.state.usuario.token != tokenLocalStorage)) {
                store.dispatch('ActionSetUsuario');
                return next({ path: "/login" });
            }
        }
        if (!to.meta.auth || !to.meta.permissions.length) {
            return next();
        }
        const { usuario } = store.state.usuario;
        if (userHasPermissions(usuario, to.meta.permissions)) {
            return next();
        }
        return next({ name: "Home" });
    });
*/

export default router;
