<template>
  <div class="mt-1">
    <div
      class="alert alert-dismissible alert-danger fade show"
      style="margin-top: 3.5rem"
    >
      <div v-html="message"></div>
      <button
        @click="ActionResetSingleErrorMessage()"
        type="button"
        class="btn-close"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["ActionResetSingleErrorMessage"]),
  },
  computed: {
    message() {
      return this.$store.getters.getSingleErrorMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";
.alert {
  left: 12.5%;
  width: 82%;
  z-index: 999;
  position: absolute;
  margin-bottom: 0 !important;
}
</style>
