import Vue from 'vue';
import vSelect from "vue-select";
import Help from "./HelpDefault.vue";
import Breadcrumbs from './Breadcrumbs.vue'
import BarraSalvarCancelar from './BarraSalvarCancelar.vue';

const components = [
    ['cc-barra-salvar-cancelar', BarraSalvarCancelar],
    ['breadcrumbs', Breadcrumbs],
    ['v-select', vSelect],
    ['help', Help]
]

components.map(component => {
    Vue.component(component[0], component[1])
})