<template>
  <div>
    <div v-if="text" class="box-breadcrumbs">
      <div v-if="text.length < 60" class="mtx-breadcrumbs">
        {{ text }}
      </div>
      <div v-else class="mtx-breadcrumbs">
        <tooltip
          :width="700"
          boxAlign="left"
          arrowAlign="left"
          :text="elipsedText"
        >
          <template #tooltip-text>
            {{ text }}
          </template>
        </tooltip>
      </div>
    </div>
    <div v-if="statusText" class="box-breadcrumbs">
      <div v-html="statusText" class="mtx-status-box" />
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";
import { DateTime } from "luxon";
const store = {
  dialog: {},
};
export default {
  data() {
    return {
      text: "",
      statusText: "",
      showStatusTooltip: false,
    };
  },
  mounted() {
    store.dialog = this;
  },
  methods: {},
  computed: {
    elipsedText() {
      if (store.dialog.text.length < 60) {
        return store.dialog.text;
      }
      return `${store.dialog.text.substring(0, 60)}...`;
    },
  },
  formatDate(timestamp) {
    const date = DateTime.fromMillis(timestamp);
    const formattedDate = date.toFormat("HH:mm - dd/MM/yy");
    return formattedDate;
  },
  setHeaderText(text) {
    store.dialog.text = text;
  },
  setHeaderStatusText(
    data = {
      text: "",
      isIntegrated: false,
      integrationDate: null,
      isIntegradoSap: false,
    }
  ) {
    const coloredDot = (text, color) => `
      <div style="
        margin: 0 10px;
        width: 10px !important;
        height: 10px !important;
        border-radius: 999px;
        background-color: ${color};
      "></div>
      <span>${text}</span>
    `;

    if (data.text) {
      const _text = `
      <div class="d-flex">
        <span>
            <b style="color: #6c757d"> Status Empresa: </b> ${data.text}
        </span>
        <span class="ms-2"> | </span>
        <div class="ms-2 d-flex align-items-center">
            <b style="color: #6c757d">WBC: </b>
            ${
              data.isIntegrated && data.integrationDate
                ? coloredDot(
                    `Integrada: ${this.formatDate(data.integrationDate)}`,
                    "green"
                  )
                : !data.isIntegrated && data.integrationDate
                ? coloredDot("Pendente", "goldenrod")
                : coloredDot("Não Integrada", "red")
            }
        </div>
        <span class="ms-2"> | </span>
        <div class="ms-2 d-flex align-items-center">
            <b style="color: #6c757d">SAP: </b>
            ${
              data.isIntegradoSap
                ? coloredDot("Integrado", "green")
                : coloredDot("Não Integrado", "red")
            }
        </div>
      </div>
      `;
      store.dialog.statusText = _text;
    } else {
      store.dialog.statusText = "";
    }
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.mtx-breadcrumbs,
.mtx-status-box {
  font-size: 16px;
  color: #418db7;
  line-height: 24px;
  border-radius: 4px;
  background: white;
  padding: 12px 16px;
  width: auto !important;
}

.mtx-status-box {
  color: $primary--black;
}
</style>
