<template>
  <div slot="main-content" class="main-content notFound-children-scroll">
    <div class="container-about">
      <div class="mt-5">
        <img
          src="@/assets/svg/mtx_logo_no_typo.svg"
          alt="Logotipo Matrix Energia"
        />
      </div>
      <div class="container-text">
        <h1>
          Para uma melhor experiência, esse sistema deve ser utilizado em telas
          a partir da resolução 1280 x 720 pixels.
        </h1>
      </div>
      <div class="container-image">
        <img src="@/assets/svg/heliceFundo.svg" alt="Imagem de um parque" />
      </div>
    </div>
  </div>
</template>

<script>
import AppContainer from "@/components/AppContainer";
export default {
  mounted() {
    this.setHeaderText("");
  },
  components: {
    AppContainer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

.notFound-content {
  width: 100%;
}


.notFound-children-scroll {
  height: calc(100vh);
  align-items: center;
}

h1 {
  font-weight: 400;
}

.main-content {
  background-color: $primary--white;
}

.container-text {
  height: 80%;
  display: flex;
  text-align: center;
  align-items: center;
}

.container-about {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px !important;
  padding-right: 0 !important;
  justify-content: space-between;

  .container-image {
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
