import { mapActions } from "vuex";
import { HTTP_RESPONSE } from "@/constants/strings";
import { logoutFromSSO, useRefreshToken } from "@/utils/authentication";
import ErrorModal from "@/components/modais/ErrorModal.vue";

export default {
  methods: {
    ...mapActions(["ActionSetToken"]),
    mxHandleRequestError({ response }, entity = "") {
      if (!response) {
        return this.mxToggleErrorMessageAlert(
          "Ocorreu um erro ao processar sua solicitação: serviço indisponível"
        );
      }
      const { status } = response;
      if (!status) {
        return this.mxToggleErrorMessageAlert(
          "Ocorreu um erro ao processar sua solicitação: serviço indisponível"
        );
      }
      if (entity.toUpperCase() === "CHANGE_NEW_PASSWORD") {
        return this.mxToggleErrorMessageAlert(
          HTTP_RESPONSE.CHANGE_NEW_PASSWORD.ERROR[status]
        );
      }
      if ([401, 403].includes(status)) {
        if (entity.toUpperCase() === "LOGIN") {
          return ErrorModal.show(HTTP_RESPONSE.USUARIO.ERROR[status]);
        }
        useRefreshToken()
          .then(() => {
            window.sessionStorage.setItem("was_token_recently_refreshed", true);
            this.$router.push("/cadastros/buscar-empresas");
          })
          .catch(() => {
            return this.mxToggleWarningModal(
              HTTP_RESPONSE.GENERIC[403],
              "Prosseguir",
              null,
              "ATENÇÃO",
              false,
              true
            )
              .then()
              .catch()
              .finally(() => {
                logoutFromSSO();
              });
          });
      }
      if (entity.toUpperCase() === "AGENDAMENTO_TAREFA") {
        return this.mxToggleErrorMessageAlert(
          HTTP_RESPONSE[entity.toUpperCase()].ERROR[status]
        );
      }
      this.mxToggleErrorMessageAlert(
        HTTP_RESPONSE[entity.toUpperCase()].ERROR[status]?.(response) ||
        HTTP_RESPONSE[entity.toUpperCase()].ERROR[status] ||
        HTTP_RESPONSE.GENERIC[status]
      );
    },
  },
};
