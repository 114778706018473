<template>
  <div class="mt-1" :key="`alert_${pendencieIndex}`">
    <div
      class="alert alert-dismissible fade show"
      :class="config.type"
      :style="{ 'margin-top': `${pendencieIndex * 3.5}rem` }"
    >
      <div v-html="config.message"></div>
      <button
        v-if="config.showButton"
        @click="ActionResetPendencieByIndex(pendencieIndex)"
        type="button"
        class="btn-close"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    config: Object,
    pendencieIndex: Number,
  },
  methods: {
    ...mapActions(["ActionResetPendencieByIndex"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";
.alert {
  left: 12.5%;
  width: 82%;
  z-index: 999;
  position: absolute;
  margin-bottom: 0 !important;
}
</style>
