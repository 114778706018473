<template>
  <div class="wrapper-sidebar" :class="[menuIsOpened ? 'open' : 'close']">
    <div
      class="sidebar-header__icon-box"
      :class="{ 'sidebar-header__icon-box--open': menuIsOpened }"
      @click="ActionToggleStatusMenu(!menuIsOpened)"
    >
      <div class="sidebar-logo">
        <img
          src="@/assets/svg/mtx_logo_no_typo_white.svg"
          class="rounded mx-auto d-block"
          alt="Logotipo Matrix Energia"
        />
      </div>
      <span v-if="menuIsOpened" class="sidebar__title ms-2"
        >Central de Cadastros</span
      >
    </div>
    <div class="sidebar__body">
      <ul class="sidebar-menu">
        <li
          class="item-menu"
          :class="{ 'item-menu-closed': !menuIsOpened }"
          v-for="(itemMenu, indexitemMenu) in FILTERED_MENU_ITEMS"
          :key="`item_menu_${indexitemMenu}`"
          :style="itemMenu.style"
        >
          <div
            @click="
              !menuIsOpened
                ? (itemMenu.showItems = true && ActionToggleStatusMenu(true))
                : null
            "
          >
            <i class="item-icon-menu" :class="itemMenu.icon"></i>
          </div>
          <div class="item-menu__body" v-if="menuIsOpened">
            <div
              class="item-menu-title"
              @click="itemMenu.showItems = !itemMenu.showItems"
            >
              <router-link
                v-if="itemMenu.url"
                tag="span"
                :to="{ path: itemMenu.url }"
              >
                {{ itemMenu.description }}
              </router-link>
              <div v-if="!itemMenu.url">
                <span>{{ itemMenu.description }}</span>
                <transition name="rotate" mode="out-in">
                  <i
                    v-if="itemMenu.showItems"
                    key="chevronUp"
                    class="icon-chevron-up fs07 ms-2"
                  ></i>
                  <i
                    v-else
                    key="chevronDown"
                    class="icon-chevron-down fs06 ms-2"
                  ></i>
                </transition>
              </div>
            </div>
            <ul v-if="menuIsOpened && itemMenu.showItems" class="box-subitens">
              <router-link
                tag="li"
                :to="{ path: subItens.url }"
                class="subitens-menu"
                v-for="(subItens, indexSubItens) in itemMenu.items"
                :key="`indexSubItens${indexSubItens}`"
              >
                {{ subItens.description }}
              </router-link>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Buffer } from "buffer";
import { mapGetters, mapActions } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import { userHasPermissions } from "@/utils/permissions";
import PermissoesENUM from "@/enums/PermissoesENUM";
export default {
  data() {
    return {
      menuItems: [
        {
          icon: "icon-cadastro",
          description: "Cadastros",
          showItems: false,
          permissions: new Array(
            ...PermissoesENUM.filter(
              (PERMISSAO) =>
                PERMISSAO == "CENTRAL_CADASTROS_CONSULTAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_EDITAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_APROVAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_PARAMETRIZAR"
            ).map((PERMISSAO) => PERMISSAO)
          ),
          items: new Array(
            {
              description: "Empresa",
              url: "/cadastros/buscar-empresas",
              permissions: new Array(),
            },
            {
              description: "Grupo Econômico",
              url: "/cadastros/buscar-grupos-economicos",
              permissions: new Array(),
            }
          ),
        },
        {
          icon: "icon-carteira-clientes",
          description: "Gestão dos Cadastros",
          showItems: false,
          permissions: new Array(
            ...PermissoesENUM.filter(
              (PERMISSAO) =>
                PERMISSAO == "CENTRAL_CADASTROS_CONSULTAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_EDITAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_APROVAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_PARAMETRIZAR"
            ).map((PERMISSAO) => PERMISSAO)
          ),
          items: new Array(
            {
              description: "Status dos Cadastros",
              url: "/cadastros/status-cadastros",
              permissions: new Array(),
            },
            {
              description: "Pesquisa",
              url: "/cadastros/pesquisa",
              permissions: new Array(),
            },
            {
              description: "Prospects",
              url: "/cadastros/prospects",
              permissions: new Array(),
            }
          ),
        },
        {
          icon: "icon-padlock",
          description: "Acessos",
          showItems: false,
          permissions: new Array(
            ...PermissoesENUM.filter(
              (PERMISSAO) =>
                PERMISSAO == "CENTRAL_CADASTROS_USUARIO_VISUALIZAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR" ||
                PERMISSAO == "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR"
            ).map((PERMISSAO) => PERMISSAO)
          ),
          items: new Array(
            {
              description: "Usuários",
              url: "/buscar-usuarios",
              permissions: new Array(
                ...PermissoesENUM.filter(
                  (PERMISSAO) =>
                    PERMISSAO == "CENTRAL_CADASTROS_USUARIO_VISUALIZAR" ||
                    PERMISSAO == "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR"
                ).map((PERMISSAO) => PERMISSAO)
              ),
            },
            {
              description: "Grupos de Acesso",
              url: "/buscar-grupos-de-acesso",
              permissions: new Array(
                ...PermissoesENUM.filter(
                  (PERMISSAO) =>
                    PERMISSAO == "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR" ||
                    PERMISSAO ==
                      "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR"
                ).map((PERMISSAO) => PERMISSAO)
              ),
            }
          ),
        },
        {
          icon: "icon-engrenagem",
          description: "Parâmetros",
          showItems: false,
          permissions: new Array(
            ...PermissoesENUM.filter(
              (PERMISSAO) => PERMISSAO == "CENTRAL_CADASTROS_PARAMETRIZAR"
            ).map((PERMISSAO) => PERMISSAO)
          ),
          items: new Array(
            {
              description: "Cadastros",
              url: "/parametros-cadastro",
              permissions: new Array(),
            },
            {
              description: "Status dos Cadastros",
              url: "/parametros-status",
              permissions: new Array(),
            }
          ),
        },
        {
          icon: "icon-help",
          description: "Ajuda",
          showItems: false,
          url: "/ajuda",
          style: "margin-top: auto;",
          permissions: new Array(),
          items: new Array(),
        },
      ],
    };
  },
  updated() {
    if (!this.mxCheckIsLogged()) {
      this.$store.dispatch("ActionSetUsuario");
    }
  },
  mixins: [MxUserUtils],
  computed: {
    ...mapGetters(["menuIsOpened", "getUsuario", "getPermissoes"]),
    FILTERED_MENU_ITEMS() {
      return this.menuItems.filter((itemMenu) => {
        if (
          itemMenu.permissions &&
          itemMenu.items &&
          itemMenu.items.length > 0
        ) {
          if (itemMenu.permissions.length == 0) {
            itemMenu.items = itemMenu.items.filter((item) => {
              if (item.permissions.length == 0) return true;
              return userHasPermissions(
                this.buscarPermissoes(),
                item.permissions
              );
            });
            return itemMenu.items.length > 0;
          } else {
            if (
              userHasPermissions(this.buscarPermissoes(), itemMenu.permissions)
            ) {
              itemMenu.items = itemMenu.items.filter((item) => {
                if (item.permissions.length == 0) return true;
                return userHasPermissions(
                  this.buscarPermissoes(),
                  item.permissions
                );
              });
              return itemMenu.items.length > 0;
            }
          }
        } else if (!itemMenu.permissions.length) {
          return true;
        }
        return userHasPermissions(
          this.buscarPermissoes(),
          itemMenu.permissions
        );
      });
    },
  },
  methods: {
    ...mapActions(["ActionToggleStatusMenu"]),
    buscarPermissoes() {
      let permissions = window.sessionStorage.getItem("p-keys");
      if (permissions) {
        permissions = JSON.parse(
          Buffer.from(permissions, "base64").toString("utf8")
        );
      }
      return permissions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
li {
  list-style-type: none;
}
.rotate-enter-active,
.rotate-leave-active {
  transition: all 0.1s;
}
.rotate-enter,
.rotate-leave-to {
  opacity: 0;
}
.wrapper-sidebar {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-right: 0 !important;
  background: $primary--black;
  overflow: auto;
  font-family: "Poppins", sans-serif !important;
}
.sidebar-header__icon-box {
  display: flex;
  color: white;
  align-items: flex-end;
  justify-content: center;
  padding: 16px 0px 16px 0px !important;
}
.sidebar-header__icon-box--open {
  padding-left: 8px !important;
  justify-content: flex-start !important;
}
.sidebar-logo {
  img {
    width: 70px;
    @include desktop {
      width: 45px;
    }
  }
}
.sidebar__title {
  color: white;
  padding-bottom: 7px;
  font-weight: bolder;
  font-size: 0.85rem;
}
.sidebar__body {
  color: white;
  font-size: 0.8rem;
}
.item-icon-menu {
  cursor: pointer;
  font-size: 1.2em;
  &:hover {
    color: $primary--orange !important;
  }
}
.sidebar-menu,
.box-subitens {
  padding: 0px !important;
}
.item-menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px 0 16px 16px !important;
}
.item-menu-closed {
  padding-left: 0 !important;
  justify-content: center;
}
.item-menu-title {
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: $primary--orange !important;
  }
}
.item-menu__body {
  margin-left: 10px;
}
.subitens-menu {
  cursor: pointer;
  padding: 8px 0px;
  &:hover {
    color: $primary--orange !important;
  }
}
.subitens-menu:first-of-type {
  padding-top: 16px;
}
.subitens-menu:last-of-type {
  padding-bottom: 0px;
}
.sidebar-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}
.router-link-exact-active {
  color: $primary--orange !important;
}
.open {
  width: 280px;
}
.close {
  width: 100px;
}
</style>
