<template>
  <div class="col col-lg-4">
    <div class="input-group">
      <label for="pontoMedicaoField">
        Ponto de Medição <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          id="pontoDeMedicao"
          :disabled="disabled"
          class="form-control"
          maxLength="13"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
    <div class="form-text">Insira os 13 caracteres.</div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { minLength, requiredIf } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "pontoMedicao",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Ponto de medição inválido");
        return true;
      }
      return false;
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (
            (this.modelProp?._codigoUnidadeConsumidora ||
              this.modelProp?.codigoUnidadeConsumidora) &&
              !this.modelProp.pontoRetaguardaAtivo
            ) {
            return false;
          }
          return true;
        }),
        minLength: minLength(13),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.form-control {
  text-transform: uppercase;
}
</style>
