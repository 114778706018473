import Vue from 'vue'
import HeaderBoxInfo from '@/components/HeaderBoxInfo.vue'
Vue.mixin({
    methods: {
        setHeaderText(text) {
            const _text = text.toUpperCase();
            HeaderBoxInfo.setHeaderText(_text);
        },
        setHeaderStatusText(text) {
            HeaderBoxInfo.setHeaderStatusText(text);
        }
    }
})