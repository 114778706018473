export default (fonts) => {
  let fontFaces = "";

  for (let i = 0; i < Object.keys(fonts).length; i++) {
    const font = fonts[Object.keys(fonts)[i]];

    for (let j = 0; j < font.fontVersions.length; j++) {
      const fontVersion = font.fontVersions[j];
      const url =
        process.env.VUE_APP_BASE_URL + font.mainUrl + fontVersion.weight;

      fontFaces += `
        @font-face {
          font-family: ${font.fontFamily};
          font-style: ${font.fontStyle};
          font-weight: ${fontVersion.weight};
          ${font.mainFile ? `src: url("${url + font.mainFile}");` : ""}
          ${
            font.files
              ? "src: " +
                fontVersion.local
                  .map((local) => `local("${local}"),`)
                  .join("") +
                font.files.map(
                  (file) => `url("${url + file.ext}") format("${file.format}")`
                ) +
                ";"
              : ""
          }
        }`;
    }
  }

  return fontFaces;
};
