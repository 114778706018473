import EnderecoModel from "./EnderecoModel";
export default class PontoDeMedicaoModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._ativo = data.ativo;
    this._idCarga = data.idCarga;
    this._endereco = data.endereco;
    this._subGrupo = data.subGrupo;
    this._pontoMedicao = data.pontoMedicao;
    this._pontoRetaguarda = data.pontoRetaguarda;
    this._modalidadeTarifaria = data.modalidadeTarifaria;
    this._pontoRetaguardaAtivo = data.pontoRetaguardaAtivo;
    this._identificacaoCargaCCEE = data.identificacaoCargaCCEE;
    this._demandaContratadaPonta = data.demandaContratadaPonta;
    this._codigoUnidadeConsumidora = data.codigoUnidadeConsumidora;
    this._codigoDistribuidoraAneel = data.codigoDistribuidoraAneel;
    this._demandaContratadaForaPonta = data.demandaContratadaForaPonta;
    this._dataMigracao = data.dataMigracao;
  }

  get id() {
    return this._id || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get ativo() {
    return this._ativo || false;
  }

  set ativo(ativo = false) {
    this._ativo = ativo;
  }

  get idCarga() {
    return this._idCarga || null;
  }

  set idCarga(idCarga = null) {
    this._idCarga = idCarga;
  }

  get endereco() {
    return this._endereco || new EnderecoModel();
  }

  set endereco(endereco = null) {
    this._endereco = endereco;
  }

  get pontoMedicao() {
    return this._pontoMedicao || null;
  }

  set pontoMedicao(pontoMedicao = null) {
    this._pontoMedicao = pontoMedicao;
  }

  get subGrupo() {
    return this._subGrupo || null;
  }

  set subGrupo(subGrupo = null) {
    this._subGrupo = subGrupo;
  }

  get pontoRetaguarda() {
    return this._pontoRetaguarda || null;
  }

  set pontoRetaguarda(pontoRetaguarda = null) {
    this._pontoRetaguarda = pontoRetaguarda;
  }

  get pontoRetaguardaAtivo() {
    return this._pontoRetaguardaAtivo || false;
  }

  set pontoRetaguardaAtivo(pontoRetaguardaAtivo = false) {
    this._pontoRetaguardaAtivo = pontoRetaguardaAtivo;
  }

  get modalidadeTarifaria() {
    return this._modalidadeTarifaria || null;
  }

  set modalidadeTarifaria(modalidadeTarifaria = null) {
    this._modalidadeTarifaria = modalidadeTarifaria;
  }

  get identificacaoCargaCCEE() {
    return this._identificacaoCargaCCEE || null;
  }

  set identificacaoCargaCCEE(identificacaoCargaCCEE = null) {
    this._identificacaoCargaCCEE = identificacaoCargaCCEE;
  }

  get demandaContratadaPonta() {
    return this._demandaContratadaPonta || null;
  }

  set demandaContratadaPonta(demandaContratadaPonta = null) {
    this._demandaContratadaPonta = demandaContratadaPonta;
  }

  get codigoUnidadeConsumidora() {
    return this._codigoUnidadeConsumidora || null;
  }

  set codigoUnidadeConsumidora(codigoUnidadeConsumidora = null) {
    this._codigoUnidadeConsumidora = codigoUnidadeConsumidora;
  }

  get codigoDistribuidoraAneel() {
    return this._codigoDistribuidoraAneel || null;
  }

  set codigoDistribuidoraAneel(codigoDistribuidoraAneel = null) {
    this._codigoDistribuidoraAneel = codigoDistribuidoraAneel;
  }

  get demandaContratadaForaPonta() {
    return this._demandaContratadaForaPonta || null;
  }

  set demandaContratadaForaPonta(demandaContratadaForaPonta = null) {
    this._demandaContratadaForaPonta = demandaContratadaForaPonta;
  }

  get dataMigracao() {
    return this._dataMigracao || null;
  }

  set dataMigracao(dataMigracao = null) {
    this._dataMigracao = dataMigracao;
  }

  getData() {
    const {
      id,
      ativo,
      idCarga,
      endereco,
      subGrupo,
      pontoMedicao,
      pontoRetaguarda,
      modalidadeTarifaria,
      pontoRetaguardaAtivo,
      demandaContratadaPonta,
      identificacaoCargaCCEE,
      codigoUnidadeConsumidora,
      codigoDistribuidoraAneel,
      demandaContratadaForaPonta,
      dataMigracao,
    } = this;

    return {
      id,
      ativo,
      idCarga,
      endereco: endereco ? endereco.getData() : null,
      subGrupo:
        typeof subGrupo === "string"
          ? subGrupo
          : !!subGrupo
          ? subGrupo.subGrupo
          : null,
      pontoMedicao,
      pontoRetaguarda: pontoRetaguarda || null,
      modalidadeTarifaria:
        typeof modalidadeTarifaria === "string"
          ? modalidadeTarifaria
          : !!modalidadeTarifaria
          ? modalidadeTarifaria.modalidade
          : null,
      pontoRetaguardaAtivo: pontoRetaguardaAtivo || false,
      identificacaoCargaCCEE,
      demandaContratadaPonta:
        demandaContratadaPonta &&
        demandaContratadaPonta.toString().includes(",")
          ? demandaContratadaPonta.toString().replace(",", ".")
          : demandaContratadaPonta,
      codigoUnidadeConsumidora,
      codigoDistribuidoraAneel,
      demandaContratadaForaPonta:
        demandaContratadaForaPonta &&
        demandaContratadaForaPonta.toString().includes(",")
          ? demandaContratadaForaPonta.toString().replace(",", ".")
          : demandaContratadaForaPonta,
      dataMigracao,
    };
  }
}
