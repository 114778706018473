export default {
  Poppins: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    mainUrl: "/fonts/poppins-v9-latin-",
    fontVersions: [
      { weight: 400, local: ["Poppins Regular", "Poppins-Regular"] },
      { weight: 500, local: ["Poppins Medium", "Poppins-Medium"] },
      { weight: 600, local: ["Poppins SemiBold", "Poppins-SemiBold"] },
      { weight: 700, local: ["Poppins Bold", "Poppins-Bold"] },
    ],
    mainFile: ".eot",
    files: [
      { format: "embedded-opentype", ext: ".eot?#iefix" },
      { format: "woff2", ext: ".woff2" },
      { format: "woff", ext: ".woff" },
      { format: "truetype", ext: ".ttf" },
      { format: "svg", ext: ".svg#Poppins" },
    ],
  },
  Roboto: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    mainUrl: "/fonts/Roboto-",
    fontVersions: [
      { weight: 100, local: ["Roboto Thin", "Roboto-Thin"] },
      { weight: 300, local: ["Roboto Light", "Roboto-Light"] },
      { weight: 400, local: ["Roboto Regular", "Roboto-Regular"] },
      { weight: 500, local: ["Roboto Medium", "Roboto-Medium"] },
      { weight: 700, local: ["Roboto Bold", "Roboto-Bold"] },
      { weight: 900, local: ["Roboto Black", "Roboto-Black"] },
    ],
    mainFile: ".ttf",
  },
};
