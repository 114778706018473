<template>
  <div class="container-callback" v-async="loading" />
</template>

<script>
import UsuarioService from "@/services/portal/UsuarioService";
import { mountUrl } from "@/utils/functionUtils";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: undefined,
    };
  },
  mounted() {
    this.loginUrlCallBack();
  },
  methods: {
    ...mapActions(["ActionSetToken"]),
    loginUrlCallBack() {
      this.loading = new Promise(() => {});
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      this.authNew(code, state);
    },
    authNew(code, state) {
      const sessionState = sessionStorage.getItem("state");
      const sessionVerifier = sessionStorage.getItem("verifier");
      if (!code || !state || !sessionState || state !== sessionState) {
        throw new Error("Could not authenticate the given user information");
      }
      const url = new URL("token", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);

      axios
        .post(
          url.href,
          {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: mountUrl(process.env.VUE_APP_REDIRECT_URI),
            client_id: process.env.VUE_APP_CLIENT_ID,
            code_verifier: sessionVerifier,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          sessionStorage.setItem(
            process.env.VUE_APP_TOKEN_KEY,
            response.data.access_token
          );
          sessionStorage.setItem(
            process.env.VUE_APP_REFRESH_TOKEN_KEY,
            response.data.refresh_token
          );
          this.ActionSetToken(response.data.access_token);
          this.getUsuario();
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    getPermissoes() {
      UsuarioService.getPermissoes()
        .then(({ data }) => {
          const sessionState = sessionStorage.getItem("state");
          const urlAnterior = new URL(atob(sessionState));
          if (urlAnterior.pathname == "/login/signout") {
            window.location.href = "/cadastros/buscar-empresas";
          } else {
            window.location.href = atob(sessionState);
          }
          sessionStorage.removeItem("state");
        })
        .catch((error) => {
          console.log("error", error);
          window.location.href = "/access-denied";
          this.mxHandleRequestError(error);
        });
    },
    getUsuario() {
      UsuarioService.getUserInfo()
        .then((response) => {
          if (response && response.data) {
            this.getPermissoes();
          }
        })
        .catch((error) => {
          console.log("error", error);
          if ([401, 403].includes(error.response.status)) {
            window.location.href = "/access-denied";
          }
        });
    },
  },
};
</script>

<style>
.container-callback {
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>
