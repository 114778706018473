<template>
  <div class="col col-lg-12 mt-3">
    <div
      class="input-group drop"
      id="drop"
      multiple="multiple"
      @dragenter="dragenter"
      @dragover.prevent
      @drop.prevent="drop"
    >
      <label id="uploadField" for="inputUploadField">
        <span class="d-flex flex-column mb-4" v-if="!model.content">
          <i class="icon-download ft"></i>
          Arraste e solte arquivos aqui ou clique para enviar
        </span>
        <span class="text-break" v-else>{{ model.content.name }}</span>
        <input
          type="file"
          :disabled="disabled"
          @change="changeFile"
          id="inputUploadField"
          class="form-control mt-2 text-center h-100"
          ref="inputUploadField"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      model: {
        name: "arquivo",
        content: "",
      },
    };
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
        if (!this.model.content && this.$refs.inputUploadField.files) {
          this.$refs.inputUploadField.files = new DataTransfer().files;
        }
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
  methods: {
    changeFile(event) {
      this.model.content = event.target.files[0];
      document.getElementById("uploadField").classList.remove("is-invalid");
      document.getElementById("uploadField").classList.add("file-attached");
    },
    drop(event) {
      if (this.disabled) return;
      document
        .getElementById("uploadField")
        .classList.remove("drop--drop-over");
      document.getElementById("uploadField").classList.remove("is-invalid");
      document.getElementById("uploadField").classList.add("file-attached");
      this.model.content = event.dataTransfer.files[0];
    },
    dragenter() {
      document.getElementById("uploadField").classList.add("drop--drop-over");
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Arquivo inválido");
        document.getElementById("uploadField").classList.add("is-invalid");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.input-group {
  height: 30vh;
}

#uploadField:hover {
  opacity: 0.5;
  transition: 0.2s ease-in-out;
}

#uploadField {
  position: relative;
  border-radius: 5px;
  border: 1px dashed $primary--black;
}

.file-attached {
  border: 2px solid $status--green !important;
}

.drop--drop-over {
  border: 2px solid $status--yellow !important;
}

.is-invalid {
  border: 1px solid $red--primary-error !important;
}

#uploadField span {
  left: 30%;
  width: 40%;
  top: 25%;
  height: 15%;
  text-align: center;
  position: absolute;
}

#uploadField span i {
  margin-bottom: 20px;
}

#uploadField input {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.ft {
  font-size: 3em;
}
</style>
