<template>
  <app-container v-if="permissionsLoaded">
    <router-view slot="main-content" class="main-content" />
  </app-container>
</template>

<script>
import { Buffer } from "buffer";
import { mapActions, mapGetters } from "vuex";
import AppContainer from "@/components/AppContainer";
import { defineAbilityFor } from "@/utils/permissions";
import UsuarioService from "@/services/portal/UsuarioService";
export default {
  data() {
    return {
      permissionsLoaded: false,
    };
  },
  components: {
    AppContainer,
  },
  computed: {
    ...mapGetters(["getUsuario", "getPermissoes"]),
  },
  methods: {
    ...mapActions([
      "ActionToggleStatusMenu",
      "ActionSetPermissoes",
      "ActionSetUsuario",
    ]),
    getUserPermissions() {
      return new Promise((resolve, reject) => {
        UsuarioService.getPermissoes()
          .then(({ data }) => {
            this.ActionSetPermissoes(data);
            window.sessionStorage.setItem(
              "p-keys",
              Buffer.from(JSON.stringify(data)).toString("base64")
            );
            this.$ability.update(defineAbilityFor(data));
            resolve();
          })
          .catch((error) => {
            const bToken = sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY);
            if (bToken) {
              window.location.href = "/access-denied";
            }
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
    getUserData() {
      return new Promise((resolve, reject) => {
        UsuarioService.getUserInfo()
          .then(({ data }) => {
            window.sessionStorage.setItem("u-keys", data);
            this.ActionSetUsuario(data);
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
  },
  async beforeMount() {
    await this.getUserData();
    await this.getUserPermissions();
    this.permissionsLoaded = true;
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.setHeaderText("");
        this.mxResetAllPendencies();
        this.setBreadcrumbsParam("");
        this.ActionToggleStatusMenu(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.main-content {
  border-radius: 5px;
  background-color: $primary--white;
  padding: 16px 24px 32px 24px !important;
}
</style>
