<template>
  <div class="page" v-async="loading">
    <div class="container row justify-content-center">
      <div class="col col-lg-8 box-content">
        <div class="mb-3 mt-3 row justify-content-center">
          <div
            class="
              d-flex
              flex-column
              col col-lg-6
              align-items-center
              justify-content-between
            "
          >
            <img
              style="max-width: 150px"
              src="@/assets/images/logo-matrix-2.png"
              class="rounded w-50 mx-auto d-block img-fluid"
              alt="Logotipo Matrix Energia"
            />

            <span class="mt-3 fw-bold fs-5">Central de Cadastros</span>
          </div>
        </div>

        <form v-if="updatingPassword" class="needs-validation" novalidate>
          <template v-if="type === 'send'">
            <div class="mb-3 row justify-content-center">
              <div
                style="text-align: center"
                class="infoTextCard col col-lg-10 col-md-10 col-sm-10"
              >
                Informe seu e-mail de cadastro para cadastrar uma nova senha.
              </div>
            </div>
            <div class="mb-3 mt-4 row justify-content-center">
              <div class="col col-lg-10 col-md-10 col-sm-10">
                <email-field
                  :modelProp="model"
                  style="width: 100%"
                  ref="email"
                />
              </div>
            </div>
            <div class="mb-3 row justify-content-center">
              <div class="col col-lg-10 col-md-10 col-sm-10">
                <span v-if="sent" class="text-confirmed"
                  >&#10004; Um e-mail alteração de senha foi enviado para o
                  e-mail informado.</span
                >
              </div>
            </div>
            <div class="mb-3 row justify-content-center">
              <div
                class="
                  row
                  justify-content-center
                  col-lg-4 col-md-4 col-sm-8
                  mb-4
                "
              >
                <button
                  @click.prevent="send"
                  class="mt-4 btn btn-primary btn-sm"
                >
                  Enviar Senha
                </button>
              </div>
            </div>
          </template>
          <template v-if="type === 'change'">
            <div class="mb-3 row justify-content-center">
              <div
                style="text-align: center"
                class="infoTextCard col col-lg-10 col-md-10 col-sm-10"
              >
                Cadastre uma nova senha para prosseguir.
              </div>
            </div>
            <div class="mb-3 mt-4 row justify-content-center">
              <div class="col col-lg-10 col-md-10 col-sm-10">
                <div class="input-group">
                  <label for="emailField">
                    Nova senha <span class="required-markup">*</span>
                    <input
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.password.$error,
                      }"
                      id="newPasswordlField"
                      v-model.trim="$v.password.$model"
                    />
                    <div class="form-text">
                      <span
                        style="color: red"
                        class="error"
                        v-if="
                          !$v.password.minLength && $v.password.$model != ''
                        "
                        >{{ INVALID_FIELD_MSG.PASSWORD_MIN_LEN }}</span
                      >
                      <span
                        v-if="!$v.password.$error || $v.password.$model === ''"
                        >Preencha com sua nova senha.</span
                      >
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3 row justify-content-center mt-4">
              <div class="col col-lg-10 col-md-10 col-sm-10">
                <div class="input-group">
                  <label for="emailField">
                    Confirme a nova senha <span class="required-markup">*</span>
                    <input
                      class="form-control"
                      type="password"
                      :class="{
                        'is-invalid': $v.repeatPassword.$error,
                      }"
                      id="emailField"
                      v-model.trim="$v.repeatPassword.$model"
                    />
                    <div class="form-text">
                      <span v-if="!$v.repeatPassword.$error"
                        >Repita aqui a nova senha.</span
                      >
                      <span
                        style="color: red"
                        class="error"
                        v-if="
                          !$v.repeatPassword.sameAsPassword &&
                          $v.repeatPassword.$model != ''
                        "
                        >Senhas precisam ser idênticas.</span
                      >
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3 row justify-content-center mt-5">
              <div
                class="
                  row
                  justify-content-center
                  col-lg-4 col-md-4 col-sm-8
                  mb-4
                "
              >
                <button @click.prevent="change" class="btn btn-primary btn-sm">
                  Alterar Senha
                </button>
              </div>
            </div>
          </template>
        </form>

        <div v-else>
          <div class="mb-4 row d-flex flex-column align-items-center">
            <div class="text-center infoTextCard">
              Senha atualizada com sucesso!
            </div>
            <div
              v-if="
                userHasBillingPermissions ||
                userHasCentralPermissions ||
                userHasEnviadorPermissions
              "
              class="text-center mt-3 infoTextCard"
            >
              Selecione a aplicação que deseja acessar:
            </div>
          </div>
          <div class="mb-4 application-options">
            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('CENTRAL')"
              v-if="userHasCentralPermissions"
            >
              Central de Cadastros
            </button>

            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('ENVIADOR')"
              v-if="userHasEnviadorPermissions"
            >
              Enviador de Propostas
            </button>

            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('BILLING')"
              v-if="userHasBillingPermissions"
            >
              Billing
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MxUserUtils from "@/mixins/mxUserUtils";
import { formIsValid } from "@/utils/validators";
import UsuarioService from "@/services/portal/UsuarioService";
import { INVALID_FIELD_MSG } from "@/constants/form.constants";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import EmailField from "@/components/forms/fields/empresa/EmailField.vue";
import ChangePasswordModel from "@/models/change-password/ChangePasswordModel";

export default {
  components: { EmailField },
  data() {
    return {
      sent: false,
      password: "",
      type: "send",
      email: undefined,
      permissoes: null,
      token: undefined,
      INVALID_FIELD_MSG,
      repeatPassword: "",
      loading: undefined,
      updatingPassword: true,
      emailEncoded: undefined,
      model: new ChangePasswordModel(),
    };
  },
  mounted() {
    this.emailEncoded = this.$route.query.emailEncoded;
    this.token = this.$route.query.token;
    if (this.$route.query.permissoesEncoded) {
      this.permissoes = JSON.parse(
        window.atob(this.$route.query.permissoesEncoded).replaceAll("'", '"')
      );
    }

    if (this.emailEncoded && this.token) {
      this.type = "change";
      this.email = window.atob(this.$route.query.emailEncoded);
      this.tryLogin(true);
    }
  },

  computed: {
    userHasCentralPermissions() {
      const permissaoCentral = this.permissoes.find((permissao) =>
        permissao.includes("CENTRAL_CADASTROS")
      );

      return (
        permissaoCentral &&
        [
          "ACESSAR_CENTRAL_CADASTROS",
          "CENTRAL_CADASTROS_CONSULTAR",
          "CENTRAL_CADASTROS_EDITAR",
          "CENTRAL_CADASTROS_APROVAR",
          "CENTRAL_CADASTROS_PARAMETRIZAR",
          "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
          "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR",
          "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
          "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR",
        ].includes(permissaoCentral)
      );
    },
    userHasEnviadorPermissions() {
      const permissaoEnviador = this.permissoes.find((permissao) =>
        permissao.includes("GERACAO_PROPOSTAS")
      );

      return (
        permissaoEnviador &&
        ["GERACAO_PROPOSTAS_EDITAR", "GERACAO_PROPOSTAS_PARAMETRIZAR"].includes(
          permissaoEnviador
        )
      );
    },
    userHasBillingPermissions() {
      const permissoesBilling = this.permissoes.filter(
        (permissao) =>
          !permissao.includes("CENTRAL_CADASTROS") &&
          !permissao.includes("GERACAO_PROPOSTAS")
      );

      return (
        permissoesBilling.length > 0 &&
        [
          "ACESSAR_BILLING",
          "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
          "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
          "CONFIGURAR_PROINFA_VISUALIZAR",
          "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
          "VISUALIZAR_FATURAMENTO",
          "REALIZAR_FATURAMENTO",
          "IMPORTACAO_LOTE_HABILITADO",
          "CONTRATOS_NAO_IMPORTADOS_HABILITADO",
          "FECHAR_FATURAMENTO_HABILITADO",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N1",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N2",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N3",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3",
          "EXPORTADOR_MONTANTES_VISUALIZAR",
          "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
          "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
          "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR",
          "ASSOCIAR_POS_VENDA_VISUALIZAR",
          "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
          "ALCADAS_APROVACAO_VISUALIZAR",
          "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
          "CURTO_PRAZO_VISUALIZAR",
          "CURTO_PRAZO_VISUALIZAR_EDITAR",
          "ROTINA_IMPORTACAO_VISUALIZAR",
          "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
          "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
          "BUSCAR_APURACOES_CCEE",
        ].findIndex((permissao) =>
          permissoesBilling.find(
            (permissaoBilling) => permissaoBilling == permissao
          )
        ) > -1
      );
    },
  },
  mixins: [MxUserUtils],
  methods: {
    redirectTo(application = "CENTRAL") {
      this.ActionSetToken(null);
      if (application === "BILLING") {
        window.location.href = process.env.VUE_APP_ROOT_BILLING_URL;
      } else if (application === "ENVIADOR") {
        window.location.href = process.env.VUE_APP_ROOT_ENVIADOR_URL;
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    send() {
      if (formIsValid(this)) {
        this.loading = UsuarioService.recuperarSenha({
          email: this.model.getData().email,
        })
          .then(() => (this.sent = true))
          .catch((error) => this.mxHandleRequestError(error));
      }
    },
    change() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = UsuarioService.resetarSenha(
          {
            senhaAntiga: "",
            senhaNova: this.password,
          },
          this.token,
          this.emailEncoded
        )
          .then(() => {
            if (
              this.userHasCentralPermissions &&
              !this.userHasEnviadorPermissions &&
              !this.userHasBillingPermissions
            ) {
              this.redirectTo("CENTRAL");
            } else if (
              !this.userHasCentralPermissions &&
              this.userHasEnviadorPermissions &&
              !this.userHasBillingPermissions
            ) {
              this.redirectTo("ENVIADOR");
            } else if (
              !this.userHasCentralPermissions &&
              !this.userHasEnviadorPermissions &&
              this.userHasBillingPermissions
            ) {
              this.redirectTo("BILLING");
            } else {
              this.showLinks = true;
              this.updatingPassword = false;
            }
          })
          .catch((error) =>
            this.mxHandleRequestError(error, "CHANGE_NEW_PASSWORD")
          );
      }
    },
    tryLogin(changePassword) {
      this.loading = this.mxTryLogin({
        username: this.email,
        password: changePassword ? this.token : this.password,
        isTrocandoSenha: true,
      })
        .then(async (userId) => {
          if (!changePassword) {
            await this.mxGetUserDetails(userId);
          }
        })
        .catch((error) => this.mxHandleRequestError(error, "LOGIN"));
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";

.icon {
  color: white !important;
}

.page {
  @include fullScreen;
  @include flex-centered;
  flex-direction: column;
  background-color: $primary--black;
  font-size: 0.8em;
}

.infoTextCard {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 24px;
}
.text-confirmed {
  font-style: italic;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 20px;
  color: #2ed47a;
}

.box-content {
  border-radius: 4px;
  background-color: $primary--white;
  padding: 0px !important;
}

.card-login-footer {
  width: 100%;
  height: 59px;
  background: #e8e8e8;
  border-radius: 0px 0px 5px 5px;
  color: #ec6723;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  padding: 10px;
}

.application-options {
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  align-items: center;
  color: $primary--orange;
  justify-content: space-evenly;

  div {
    cursor: pointer;
  }
}

.application-options div:hover {
  text-decoration: underline;
}
.btn-redirect {
  min-width: 150px;
}
</style>
