var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-5"},[_c('div',{staticClass:"row mt-5 d-flex align-items-center"},[_c('switch-agente-CCEE-field',{ref:"agenteCcee",attrs:{"modelProp":_vm.dadosGeraisModel,"disabled":(_vm.ALREADY_HAS_AGENTE && _vm.IS_AGENTE_CCEE) ||
        _vm.IS_EMPRESA_REPRESENTADA ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'))},on:{"clearRepresentanteFields":_vm.clearRepresentanteFields,"handleSwitchChange":_vm.handleSwitchChange}}),_c('codigo-agente-field',{ref:"codigoAgenteField",attrs:{"disabled":!_vm.IS_AGENTE_CCEE ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"recentUpdatedField":_vm.recentUpdatedField('codigoAgente')}}),_c('switch-empresa-representada-field',{ref:"empresaRepresentada",attrs:{"modelProp":_vm.dadosGeraisModel,"disabled":_vm.IS_AGENTE_CCEE ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'))},on:{"clearRepresentante":_vm.clearRepresentante,"handleSwitchChange":_vm.handleSwitchChange}}),_c('representante-empresa-field',{ref:"representanteField",attrs:{"modelProp":_vm.dadosGeraisModel,"disabled":!_vm.IS_EMPRESA_REPRESENTADA ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"invalidYellow":_vm.invalidYellow(_vm.empresa ? _vm.empresa.representante : undefined),"recentUpdatedField":_vm.recentUpdatedField('representante')}})],1),_c('div',{staticClass:"row mt-5 d-flex align-items-center"},[_c('categoria-empresa-field',{ref:"categoriaField",attrs:{"modelProp":_vm.dadosGeraisModel,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('classe-agente-field',{ref:"classeAgenteField",attrs:{"modelProp":_vm.dadosGeraisModel,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('classeAgente')}})],1),_c('div',{staticClass:"row mt-5 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col col-lg-6 w-50 col-md-8"}),_c('div',{staticClass:"col col-lg-4 w-50"},[(!_vm.editing && _vm.showSavePendencyMessage)?_c('div',{staticClass:"mb-2 d-flex align-items-center justify-content-end"},[_c('span',{staticClass:"save-message"},[_vm._v(" É necessário salvar as alterações para atualizar os dados. ")])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-secondary ms-3",on:{"click":function($event){return _vm.$router.push({ name: 'BuscarEmpresas' })}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":function($event){_vm.updateDadosGerais();
            _vm.updateCodigoAgente();}}},[_vm._v(" Atualizar ")])])])]),_c('div',{staticClass:"row mt-5"},[_c('form-row-header',{attrs:{"text":"Cadastrar Perfil de Agente"}})],1),_c('div',{staticClass:"row mt-3"},[_c('switch-dados-provisorios-field',{ref:"dadosProField",attrs:{"modelProp":_vm.model,"empresa":_vm.empresa,"disabled":!_vm.IS_AGENTE_CCEE ||
        _vm.ALREADY_HAS_AGENTE ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'))},on:{"updateIsProvisorio":_vm.updateIsProvisorio}}),_c('tipo-energia-field',{ref:"tipoEnergiaField",attrs:{"disabled":!_vm.IS_AGENTE_CCEE ||
        _vm.IS_SIGLA_PROVISORIA ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"recentUpdatedField":_vm.recentUpdatedField('tipoEnergia')}}),_c('codigo-perfil-agente-field',{ref:"codigoPerfilAgenteField",attrs:{"disabled":!_vm.IS_AGENTE_CCEE ||
        _vm.IS_SIGLA_PROVISORIA ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"recentUpdatedField":_vm.recentUpdatedField('perfilAgente')}}),_c('sigla-perfil-agente-field',{ref:"siglaPerfilAgenteField",attrs:{"disabled":!_vm.IS_AGENTE_CCEE ||
        _vm.IS_SIGLA_PROVISORIA ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"recentUpdatedField":_vm.recentUpdatedField('sigla')}})],1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col col-lg-auto ms-auto"},[(_vm.editing)?_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.resetFields}},[_vm._v(" Cancelar ")]):_c('button',{staticClass:"btn btn-secondary",attrs:{"disabled":_vm.IS_SIGLA_PROVISORIA},on:{"click":_vm.clearFields}},[_vm._v(" Limpar ")]),(
          _vm.editing &&
          (this.$can('CENTRAL_CADASTROS_EDITAR') ||
            this.$can('CENTRAL_CADASTROS_APROVAR') ||
            this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'))
        )?_c('button',{staticClass:"btn btn-primary ms-3",attrs:{"disabled":(!_vm.editing && _vm.IS_SIGLA_PROVISORIA) || !_vm.IS_AGENTE_CCEE},on:{"click":_vm.add}},[_vm._v(" Atualizar ")]):(
          this.$can('CENTRAL_CADASTROS_EDITAR') ||
          this.$can('CENTRAL_CADASTROS_APROVAR') ||
          this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        )?_c('button',{staticClass:"btn btn-primary ms-3",attrs:{"disabled":_vm.IS_SIGLA_PROVISORIA || !_vm.IS_AGENTE_CCEE},on:{"click":_vm.add}},[_vm._v(" Adicionar perfil de agente ")]):_vm._e()])]),_c('div',{staticClass:"row mt-3"},[_c('table-agentes-cadastrados',{attrs:{"agentesList":_vm.list,"editDisabled":_vm.ALREADY_HAS_AGENTE && _vm.isProvisorio,"disabled":!_vm.IS_AGENTE_CCEE ||
        (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')),"recentUpdatedFields":_vm.recentUpdatedFields,"recentCreatedDadosBancarios":_vm.recentCreatedDadosBancarios},on:{"sortBy":_vm.sortBy,"editAgente":_vm.edit,"deleteAgente":_vm.remove}})],1),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-secondary-blue ms-3",attrs:{"disabled":!_vm.IS_AGENTE_CCEE ||
          (!this.$can('CENTRAL_CADASTROS_EDITAR') &&
            !this.$can('CENTRAL_CADASTROS_APROVAR') &&
            !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'))},on:{"click":function($event){return _vm.sincronizarWbc()}}},[_c('strong',[_vm._v("Importar")]),_vm._v(" dados do "),_c('strong',[_vm._v("WBC")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }