<template>
  <div
    class="home-page container-fluid"
    @toggleStatusMenu="ActionToggleStatusMenu"
    :menuIsOpened="menuIsOpened"
  >
    <div class="d-flex">
      <sidebar />
      <div
        @click="$emit('toggleStatusMenu', false)"
        style="flex-grow: 1"
        :class="[
          'col',
          'ps-0',
          'pb-3',
          'd-flex',
          'flex-column',
          menuIsOpened ? 'col-10' : 'col-11',
        ]"
      >
        <navbar>
          <div slot="navbar-items" class="d-flex ms-auto col col-11">
            <div
              class="d-flex col col-5 align-items-center justify-content-start"
            >
              <div>
                <div
                  v-if="development"
                  class="desenvolvimento-environment px-2 py-1 tag"
                >
                  <img :src="tool" />
                  <span class="ms-1 me-2">
                    <span class="tag-label">Ambiente de </span>
                    Desenvolvimento
                  </span>
                </div>
                <div
                  v-else-if="homologation"
                  class="homologacao-environment px-2 py-1 tag"
                >
                  <img :src="hand" />
                  <span class="ms-1 me-2">
                    <span class="tag-label">Ambiente de</span>
                    Homologação
                  </span>
                </div>
                <div
                  v-else-if="!production"
                  class="local-environment px-2 py-1 tag"
                >
                  <img :src="home" style="width: 13px; margin-top: -2px" />
                  <span class="ms-1 me-2">
                    <span class="tag-label">Ambiente</span> Local</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex ms-auto align-items-center justify-content-end">
              <div class="navbar-item d-flex align-items-center">
                <router-link
                  tag="i"
                  class="icon icon-avatar cursop"
                  to="/perfil"
                />
                <router-link class="text-decoration-none" to="/perfil">
                  <span class="text-span">
                    {{ getUsuario.nome }}
                  </span>
                </router-link>
              </div>
              <span class="navbar-item">|</span>
              <i @click="logout" class="navbar-item icon cursop icon-logout" />
            </div>
          </div>
        </navbar>
        <toast />
        <alert-message
          v-for="(pendencie, pendencieIndex) in getPendencies"
          :key="`pendencie_${pendencieIndex}`"
          :config="pendencie"
          :pendencieIndex="pendencieIndex"
        />
        <alert-single-message v-if="getSingleErrorMessage" />
        <div class="scrollabel-content">
          <alert-immutable-message
            v-for="(
              immutablePendencie, immutablePendencieIndex
            ) in getImmutablePendencies"
            :key="`pendencie_${immutablePendencieIndex}`"
            :config="immutablePendencie"
            :pendencieIndex="immutablePendencieIndex"
          />
          <div class="container">
            <div class="home-header mt-3">
              <div class="my-1">
                <breadcrumbs />
              </div>
              <div class="my-1">
                <header-box-info />
              </div>
            </div>
            <div class="rounded-3">
              <slot name="main-content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import { mapActions, mapGetters } from "vuex";
import { CONFIRMATION } from "@/constants/strings";
import Navbar from "@/components/navigation/Navbar";
import AppContainer from "@/components/AppContainer";
import AlertMessage from "@/components/AlertMessage";
import Sidebar from "@/components/navigation/Sidebar";
import HeaderBoxInfo from "@/components/HeaderBoxInfo";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { logoutFromSSO } from "@/utils/authentication";
import AlertSingleMessage from "@/components/AlertSingleMessage.vue";
import AlertImmutableMessage from "@/components/AlertImmutableMessage";

export default {
  data() {
    return {
      production: false,
      development: false,
      homologation: false,
    };
  },
  computed: {
    ...mapGetters([
      "menuIsOpened",
      "getUsuario",
      "getPendencies",
      "getSingleErrorMessage",
      "getImmutablePendencies",
      "getSingleErrorMessage",
    ]),
    hand: function () {
      return "/svg/hand.svg";
    },
    home: function () {
      return "/svg/home.svg";
    },
    tool: function () {
      return "/svg/tool.svg";
    },
  },
  mounted() {
    this.getCurrentEnvironment();
  },
  methods: {
    ...mapActions(["ActionSetToken", "ActionToggleStatusMenu"]),

    getCurrentEnvironment() {
      if (process.env.VUE_APP_ENVIRONMENT === "DEV") {
        return (this.development = true);
      }
      if (process.env.VUE_APP_ENVIRONMENT === "HML") {
        return (this.homologation = true);
      }
      if (process.env.VUE_APP_ENVIRONMENT === "PRD") {
        return (this.production = true);
      }
    },
    logout() {
      this.mxToggleWarningModal(CONFIRMATION.APP.LOGOUT, "Sim")
        .then(() => {
          this.ActionSetToken(null);
          logoutFromSSO();
        })
        .catch(() => {});
    },
  },
  components: {
    Toast,
    Navbar,
    Sidebar,
    Breadcrumbs,
    AlertMessage,
    AppContainer,
    AlertMessage,
    HeaderBoxInfo,
    AlertSingleMessage,
    AlertImmutableMessage,
    AlertSingleMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

.home-header {
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
  }
}

.container-fluid {
  padding: 0 !important;
}

.home-page {
  background-color: $primary--gray;
}

.scrollabel-content {
  @include fullScreen;
  overflow: auto;
  width: 100% !important;
  height: calc(100vh - 50px - 1rem);
}

.text-span {
  color: #212529;
}

.container {
  @include mega-hd {
    max-width: 110vw !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  @include full-hd {
    max-width: 110vw !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  @include low-fhd {
    max-width: 110vw !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.navStyle {
  width: 100% !important;
}

.tag {
  font-size: 16px;

  @include tablet-small {
    font-size: 12px;

    .tag-label {
      display: none;
    }
  }
}
</style>
