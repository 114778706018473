

export default class ChangePassword {
    constructor({
        email,
        senha,
         } = new Object()) {
        this._email = email;
        this._senha = senha;
    
    }

    set email(email = null) {
        this._email = email;
    }

    get email() {
        return this._email || null;
    }

    set senha(senha = null) {
        this._senha = senha;
    }

    get senha() {
        return this._senha || null;
    }

    

    getData() {
        const {
            email,
            senha,
             } = this;
        return {
            email,senha
        }
    }
}