<template>
  <div class="col col-lg-12">
    <label for="bancoField">
      Banco <span class="required-markup">*</span>
      <span class="recent-updated-label" v-if="recentUpdatedField">
        <tooltip-informacao-alterada />
      </span>
      <div class="input-group">
        <input
          list="encodings"
          :disabled="disabled"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="bancoField"
          v-model="$v.model.content.$model"
        />
        <datalist id="encodings">
          <option
            :value="`${banco.codigoBanco}  -  ${banco.nomeBanco}`"
            v-for="(banco, bancoIndex) in list"
            :key="`banco_${bancoIndex}`"
          >
          </option>
        </datalist>
      </div>
      <div class="form-text">
        Digite o nome ou código do banco para encontrar.
      </div>
    </label>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { required } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import DadosBancariosService from "@/services/portal/empresa/DadosBancariosService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      loadList: undefined,
      model: {
        name: "nomeBanco",
        content: "",
      },
      list: new Array(),
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  mounted() {
    this.loadList = DadosBancariosService.getBancosList()
      .then((response) => (this.list = response.data))
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Banco inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
        bancoIsInDatalist: function () {
          const formatedName = this.model.content.split("  -  ");
          return this.list.some((banco) => banco.nomeBanco === formatedName[1]);
        },
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
