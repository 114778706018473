var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"page"},[_c('div',{staticClass:"container row justify-content-center"},[_c('div',{staticClass:"col col-lg-8 box-content"},[_vm._m(0),(_vm.updatingPassword)?_c('form',{staticClass:"needs-validation",attrs:{"novalidate":""}},[(_vm.type === 'send')?[_vm._m(1),_c('div',{staticClass:"mb-3 mt-4 row justify-content-center"},[_c('div',{staticClass:"col col-lg-10 col-md-10 col-sm-10"},[_c('email-field',{ref:"email",staticStyle:{"width":"100%"},attrs:{"modelProp":_vm.model}})],1)]),_c('div',{staticClass:"mb-3 row justify-content-center"},[_c('div',{staticClass:"col col-lg-10 col-md-10 col-sm-10"},[(_vm.sent)?_c('span',{staticClass:"text-confirmed"},[_vm._v("✔ Um e-mail alteração de senha foi enviado para o e-mail informado.")]):_vm._e()])]),_c('div',{staticClass:"mb-3 row justify-content-center"},[_c('div',{staticClass:"\n                row\n                justify-content-center\n                col-lg-4 col-md-4 col-sm-8\n                mb-4\n              "},[_c('button',{staticClass:"mt-4 btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_vm._v(" Enviar Senha ")])])])]:_vm._e(),(_vm.type === 'change')?[_vm._m(2),_c('div',{staticClass:"mb-3 mt-4 row justify-content-center"},[_c('div',{staticClass:"col col-lg-10 col-md-10 col-sm-10"},[_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"emailField"}},[_vm._v(" Nova senha "),_c('span',{staticClass:"required-markup"},[_vm._v("*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.password.$error,
                    },attrs:{"type":"password","id":"newPasswordlField"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"form-text"},[(
                        !_vm.$v.password.minLength && _vm.$v.password.$model != ''
                      )?_c('span',{staticClass:"error",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.INVALID_FIELD_MSG.PASSWORD_MIN_LEN))]):_vm._e(),(!_vm.$v.password.$error || _vm.$v.password.$model === '')?_c('span',[_vm._v("Preencha com sua nova senha.")]):_vm._e()])])])])]),_c('div',{staticClass:"mb-3 row justify-content-center mt-4"},[_c('div',{staticClass:"col col-lg-10 col-md-10 col-sm-10"},[_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"emailField"}},[_vm._v(" Confirme a nova senha "),_c('span',{staticClass:"required-markup"},[_vm._v("*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.repeatPassword.$model),expression:"$v.repeatPassword.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.repeatPassword.$error,
                    },attrs:{"type":"password","id":"emailField"},domProps:{"value":(_vm.$v.repeatPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.repeatPassword, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"form-text"},[(!_vm.$v.repeatPassword.$error)?_c('span',[_vm._v("Repita aqui a nova senha.")]):_vm._e(),(
                        !_vm.$v.repeatPassword.sameAsPassword &&
                        _vm.$v.repeatPassword.$model != ''
                      )?_c('span',{staticClass:"error",staticStyle:{"color":"red"}},[_vm._v("Senhas precisam ser idênticas.")]):_vm._e()])])])])]),_c('div',{staticClass:"mb-3 row justify-content-center mt-5"},[_c('div',{staticClass:"\n                row\n                justify-content-center\n                col-lg-4 col-md-4 col-sm-8\n                mb-4\n              "},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.change.apply(null, arguments)}}},[_vm._v(" Alterar Senha ")])])])]:_vm._e()],2):_c('div',[_c('div',{staticClass:"mb-4 row d-flex flex-column align-items-center"},[_c('div',{staticClass:"text-center infoTextCard"},[_vm._v(" Senha atualizada com sucesso! ")]),(
              _vm.userHasBillingPermissions ||
              _vm.userHasCentralPermissions ||
              _vm.userHasEnviadorPermissions
            )?_c('div',{staticClass:"text-center mt-3 infoTextCard"},[_vm._v(" Selecione a aplicação que deseja acessar: ")]):_vm._e()]),_c('div',{staticClass:"mb-4 application-options"},[(_vm.userHasCentralPermissions)?_c('button',{staticClass:"btn btn-primary btn-sm btn-redirect",on:{"click":function($event){return _vm.redirectTo('CENTRAL')}}},[_vm._v(" Central de Cadastros ")]):_vm._e(),(_vm.userHasEnviadorPermissions)?_c('button',{staticClass:"btn btn-primary btn-sm btn-redirect",on:{"click":function($event){return _vm.redirectTo('ENVIADOR')}}},[_vm._v(" Enviador de Propostas ")]):_vm._e(),(_vm.userHasBillingPermissions)?_c('button',{staticClass:"btn btn-primary btn-sm btn-redirect",on:{"click":function($event){return _vm.redirectTo('BILLING')}}},[_vm._v(" Billing ")]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3 mt-3 row justify-content-center"},[_c('div',{staticClass:"\n            d-flex\n            flex-column\n            col col-lg-6\n            align-items-center\n            justify-content-between\n          "},[_c('img',{staticClass:"rounded w-50 mx-auto d-block img-fluid",staticStyle:{"max-width":"150px"},attrs:{"src":require("@/assets/images/logo-matrix-2.png"),"alt":"Logotipo Matrix Energia"}}),_c('span',{staticClass:"mt-3 fw-bold fs-5"},[_vm._v("Central de Cadastros")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3 row justify-content-center"},[_c('div',{staticClass:"infoTextCard col col-lg-10 col-md-10 col-sm-10",staticStyle:{"text-align":"center"}},[_vm._v(" Informe seu e-mail de cadastro para cadastrar uma nova senha. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3 row justify-content-center"},[_c('div',{staticClass:"infoTextCard col col-lg-10 col-md-10 col-sm-10",staticStyle:{"text-align":"center"}},[_vm._v(" Cadastre uma nova senha para prosseguir. ")])])}]

export { render, staticRenderFns }