export default class AgentePerfilModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._tipoEnergia = data.tipoEnergia;
    this._wbcSiglaCcee = data.wbcSiglaCcee;
    this._siglaPerfilAgente = data.siglaPerfilAgente;
    this._codigoPerfilAgente = data.codigoPerfilAgente;
    this._isProvisorio = data.isProvisorio;
    
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id || null;
  }

  set wbcSiglaCcee(wbcSiglaCcee = null) {
    this._wbcSiglaCcee = wbcSiglaCcee;
  }

  get wbcSiglaCcee() {
    return this._wbcSiglaCcee;
  }

  set tipoEnergia(tipoEnergia = null) {
    this._tipoEnergia = tipoEnergia;
  }

  get tipoEnergia() {
    return this._tipoEnergia || null;
  }

  set siglaPerfilAgente(siglaPerfilAgente = null) {
    this._siglaPerfilAgente = siglaPerfilAgente;
  }

  get siglaPerfilAgente() {
    return this._siglaPerfilAgente || null;
  }

  set codigoPerfilAgente(codigoPerfilAgente = null) {
    this._codigoPerfilAgente = codigoPerfilAgente;
  }

  get codigoPerfilAgente() {
    return this._codigoPerfilAgente || null;
  }

  
  set isProvisorio(isProvisorio = false) {
    this._isProvisorio = isProvisorio;
  }

  get isProvisorio() {
    return this._isProvisorio || false;
  }





  getData() {
    const {
      id,
      tipoEnergia,
      wbcSiglaCcee,
      siglaPerfilAgente,
      codigoPerfilAgente,
      isProvisorio,
    } = this;
    return {
      id,
      tipoEnergia,
      wbcSiglaCcee,
      siglaPerfilAgente,
      codigoPerfilAgente,
      isProvisorio,
    };
  }
}
