const pages = {
  state: {
    showMenu: false,
    singleErrorMessage : '',
    pendencies: new Array(),
    singleErrorMessage: "",
    immutablePendencies: new Array(),
    parametrosPageLoading: undefined,
    toast: {
      title: "",
      message: "",
      show: false,
    },
  },
  getters: {
    menuIsOpened(state) {
      return state.showMenu;
    },
    getToast(state) {
      return state.toast;
    },
    getPendencies(state) {
      return state.pendencies;
    },
    getSingleErrorMessage(state) {
      return state.singleErrorMessage;
    },
    getImmutablePendencies(state) {
      return state.immutablePendencies;
    },
    getParametrosPageLoading(state) {
      return state.parametrosPageLoading;
    },
  },
  mutations: {
    toggleParametrosPageIsLoading(state, newState) {
      state.parametrosPageLoading = newState;
    },
    toggleStatusMenu(state, newstate) {
      state.showMenu = newstate;
    },
    toggleDangerAlert(state, { message, showButton }) {
      if (
        !state.pendencies.some((pendencie) => pendencie.message === message)
      ) {
        state.pendencies.push({ type: "alert-danger", message, showButton });
      }
    },
    toggleWarningAlert(state, { message, showButton, immutable }) {
      if (immutable) {
        if (
          !state.immutablePendencies.some(
            (pendencie) => pendencie.message === message
          )
        ) {
          if (message.toUpperCase().includes("CAMPOS ALTERADOS:")) {
            let hasCamposAlterados = state.immutablePendencies.findIndex(
              (pendency) => {
                return pendency.message
                  .toUpperCase()
                  .includes("CAMPOS ALTERADOS:");
              }
            );
            if (hasCamposAlterados > -1) {
              state.immutablePendencies.splice(hasCamposAlterados, 1);
            }
          }
          if (
            message.includes(
              "Os campos pendentes não obrigatórios estão destacados em"
            )
          ) {
            state.immutablePendencies.unshift({
              type: "alert-warning",
              message,
            });
          } else {
            state.immutablePendencies.push({ type: "alert-warning", message });
          }
        }
      } else if (
        !state.pendencies.some((pendencie) => pendencie.message === message)
      ) {
        state.pendencies.push({
          type: "alert-warning",
          message,
          showButton,
          immutable,
        });
      }
    },
    getWarningMessageAlert(state) {
      return state.getMessageWarningAlert;
    },
    toggleToast(state, { title, message }) {
      state.toast = {
        title,
        message,
        show: true,
      };
    },
    resetToast(state) {
      state.toast = {
        title: "",
        message: "",
        show: false,
      };
    },
    resetPendencieByIndex(state, pendencieIndex) {
      state.pendencies.splice(pendencieIndex, 1);
    },
    setPendencie(state, value) {
      state.pendencies.push(value);
    },
    setSingleErrorMessage(state, value) {
      state.singleErrorMessage = value;
    },
    resetSingleErrorMessage(state) {
      state.singleErrorMessage = "";
    },
    resetErrorPendencies(state) {
      state.pendencies = state.pendencies.filter(
        (pendencie) => pendencie.type !== "alert-danger"
      );
    },
    resetWarningPendencies(state, resetImmutable) {
      if (resetImmutable) state.immutablePendencies = new Array();
      state.pendencies = state.pendencies.filter((pendencie) => {
        return pendencie.type !== "alert-warning" || pendencie.immutable;
      });
    },
    clearCamposAlteradosPendencies(state) {
      if (state.immutablePendencies.length > 0) {
        let camposAlterados = state.immutablePendencies.findIndex(
          (pendency) => {
            return pendency.message.toUpperCase().includes("CAMPOS ALTERADOS:");
          }
        );
        if (camposAlterados > -1) {
          state.immutablePendencies.splice(camposAlterados, 1);
        }
      }
    },
    clearCamposPendentesPendencies(state) {
      if (state.immutablePendencies.length > 0) {
        let camposPendentes = state.immutablePendencies.findIndex(
          (pendency) => {
            return pendency.message.includes(
              "Os campos pendentes não obrigatórios estão destacados em"
            );
          }
        );
        if (camposPendentes > -1) {
          state.immutablePendencies.splice(camposPendentes, 1);
        }
      }
    },
    resetAllPendencies(state) {
      state.pendencies = new Array();
      state.immutablePendencies = new Array();
    },
  },
  actions: {
    ActionToggleParametrosPageIsLoading(context, value) {
      context.commit("toggleParametrosPageIsLoading", value);
    },
    ActionToggleStatusMenu(context, value) {
      context.commit("toggleStatusMenu", value);
    },
    ActionToggleDangerAlert(context, value) {
      context.commit("toggleDangerAlert", value);
    },
    ActionToggleWarningAlert(context, value) {
      context.commit("toggleWarningAlert", value);
    },
    ActionToggleToast(context, value) {
      context.commit("toggleToast", value);
    },
    ActionResetToast(context) {
      context.commit("resetToast");
    },
    ActionSetPendencie(context, value) {
      context.commit("setPendencie", value);
    },
    ActionSetSingleErrorMessage(context, value) {
      context.commit("setSingleErrorMessage", value);
    },
    ActionResetSingleErrorMessage(context) {
      context.commit("resetSingleErrorMessage");
    },
    ActionResetErrorPendencies(context) {
      context.commit("resetErrorPendencies");
    },
    ActionResetWarningPendencies(context, value) {
      context.commit("resetWarningPendencies", value);
    },
    ActionClearCamposAlteradosPendencies(context, value) {
      context.commit("clearCamposAlteradosPendencies", value);
    },
    ActionClearCamposPendentesPendencies(context, value) {
      context.commit("clearCamposPendentesPendencies", value);
    },
    ActionResetAllPendencies(context) {
      context.commit("resetAllPendencies");
    },
    ActionResetPendencieByIndex(context, value) {
      context.commit("resetPendencieByIndex", value);
    },
  },
};
export { pages };
