import Empresas from "@/pages/empresas/Empresas.vue";
import Prospects from "@/pages/empresas/Prospects.vue";
import BuscarEmpresas from "@/pages/empresas/BuscarEmpresas.vue";
import GrupoEconomico from "@/pages/grupo-economico/GrupoEconomico";
import Statuscadastros from "../pages/status-cadastros/StatusCadastros.vue";
import DadosCadastroEmpresa from "@/pages/empresas/DadosCadastroEmpresa.vue";
import BuscarGrupoEconomico from "@/pages/grupo-economico/BuscarGrupoEconomico";

const routes = [
  {
    path: "/cadastros/empresas",
    name: "CadastroEmpresas",
    component: Empresas,
    meta: {
      auth: true,
      breadcrumb: new Array("Cadastro", "Empresa"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/buscar-empresas",
    name: "BuscarEmpresas",
    component: BuscarEmpresas,
    meta: {
      auth: true,
      breadcrumb: new Array("Empresas"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/buscar-grupos-economicos",
    name: "BuscarGrupoEconomico",
    component: BuscarGrupoEconomico,
    meta: {
      auth: true,
      breadcrumb: new Array("Grupos Econômicos"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/grupo-economico",
    name: "GrupoEconomico",
    component: GrupoEconomico,
    meta: {
      auth: true,
      breadcrumb: new Array("Grupos Econômicos"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/status-cadastros",
    name: "Statuscadastros",
    component: Statuscadastros,
    meta: {
      auth: true,
      breadcrumb: new Array("Status dos Cadastros"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/pesquisa",
    name: "Pesquisa",
    component: DadosCadastroEmpresa,
    meta: {
      auth: true,
      breadcrumb: new Array("Gerenciamento dos Cadastros"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
  {
    path: "/cadastros/prospects",
    name: "Prospects",
    component: Prospects,
    meta: {
      auth: true,
      dadosFaltantes: false,
      breadcrumb: new Array("Prospects"),
      permissions: new Array(
        "CENTRAL_CADASTROS_CONSULTAR",
        "CENTRAL_CADASTROS_EDITAR",
        "CENTRAL_CADASTROS_APROVAR",
        "CENTRAL_CADASTROS_PARAMETRIZAR"
      ),
    },
  },
];

export default routes;
