<template>
  <div class="page" v-async="loading">
    <div class="container row justify-content-center">
      <div class="col col-lg-6 box-content">
        <div class="mb-3 mt-3 row justify-content-center">
          <div
            class="
              d-flex
              flex-column
              col col-lg-6
              align-items-center
              justify-content-between
            "
          >
            <img
              style="max-width: 150px"
              src="@/assets/images/logo-matrix-2.png"
              class="rounded w-50 mx-auto d-block img-fluid"
              alt="Logotipo Matrix Energia"
            />
            <span class="mt-3 fw-bold fs-5">Central de Cadastros</span>
          </div>
        </div>
        <form class="needs-validation" novalidate>
          <div class="mb-3 row justify-content-center">
            <div class="col col-lg-10 col-md-10 col-sm-10">
              <span v-if="$v.credentials.username.$error" class="text-danger">
                {{ INVALID_FIELD_MSG.EMAIL }}
              </span>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="icon icon-user"></i>
                </span>
                <input
                  v-model.lazy.trim="$v.credentials.username.$model"
                  type="email"
                  placeholder="usuário"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.credentials.username.$error,
                  }"
                  aria-describedby="emailHelp"
                />
              </div>
            </div>
          </div>
          <div class="mb-3 row justify-content-center">
            <div class="col col-lg-10 col-md-10 col-sm-10">
              <span v-if="$v.credentials.password.$error" class="text-danger">
                {{ INVALID_FIELD_MSG.PASSWORD }}
              </span>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="icon icon-key"></i>
                </span>
                <input
                  v-model.lazy.trim="$v.credentials.password.$model"
                  type="password"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.credentials.password.$error,
                  }"
                  placeholder="senha"
                />
              </div>
            </div>
          </div>
          <div class="mb-3 row justify-content-center">
            <div
              class="row justify-content-center col-lg-4 col-md-4 col-sm-8 mb-4"
            >
              <button @click.prevent="tryLogin" class="btn btn-primary btn-sm">
                Login
              </button>
            </div>
          </div>
        </form>
        <div class="card-login-footer">
          <span style="cursor: pointer" @click="forgotPassword"
            >Esqueceu a senha ?</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import { INVALID_FIELD_MSG } from "@/constants/form.constants";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loading: undefined,
      credentials: {
        username: "",
        password: "",
      },
      INVALID_FIELD_MSG,
    };
  },
  validations: {
    credentials: {
      username: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  mixins: [MxUserUtils],
  computed: {
    ...mapGetters(["getUsuario"]),
  },
  methods: {
    ...mapActions(["ActionSetUsuario"]),
    tryLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = this.mxTryLogin(this.credentials)
          .then(async (userId) => {
            await this.mxGetUserDetails(userId);
          })
          .catch((error) => this.mxHandleRequestError(error, "LOGIN"));
      }
    },
    forgotPassword() {
      this.$router.push({ path: "/recuperar-senha" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";

.icon {
  color: white !important;
}

.page {
  @include fullScreen;
  @include flex-centered;
  flex-direction: column;
  background-color: $primary--black;
}

.box-content {
  border-radius: 4px;
  background-color: $primary--white;
  padding: 0px !important;
}
.card-login-footer {
  width: 100%;
  height: 59px;
  background: #e8e8e8;
  border-radius: 0px 0px 5px 5px;
  color: #ec6723;
  display: flex;
  justify-content: center;
  align-items: center;
}
form {
  padding: 10px;
}
</style>
