import {
  challengeFromVerifier,
  generateRandomString,
  mountUrl,
} from "@/utils/functionUtils";
import axios from "axios";
import { Buffer } from "buffer";

export const loginRedirect = async () => {
  const state = btoa(window.location.href);

  const verifier = await generateRandomString();

  sessionStorage.setItem("state", state);
  sessionStorage.setItem("verifier", verifier);

  challengeFromVerifier(verifier).then((challenge) => {
    const data = {
      response_type: "code",
      client_id: process.env.VUE_APP_CLIENT_ID,
      scope: `openid profile offline_access ${process.env.VUE_APP_SCOPE}`,
      state: state,
      redirect_uri: mountUrl(process.env.VUE_APP_REDIRECT_URI),
      code_challenge: challenge,
      code_challenge_method: "S256",
    };

    const params = new URLSearchParams(data);
    const url = new URL("authorize", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);
    url.search = params.toString();

    window.location.href = url.href;
  });
};

export async function useRefreshToken() {
  return new Promise((resolve, reject) => {
    const url = new URL("token", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);
    const refresh_token = sessionStorage.getItem(
      process.env.VUE_APP_REFRESH_TOKEN_KEY
    );

    if (!refresh_token) reject();

    axios
      .post(
        url.href,
        {
          grant_type: "refresh_token",
          refresh_token,
          client_id: process.env.VUE_APP_CLIENT_ID,
          scope: `openid profile offline_access ${process.env.VUE_APP_SCOPE}`,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        sessionStorage.setItem(
          process.env.VUE_APP_TOKEN_KEY,
          response.data.access_token
        );
        sessionStorage.setItem(
          process.env.VUE_APP_REFRESH_TOKEN_KEY,
          response.data.refresh_token
        );
        sessionStorage.setItem("was_token_recently_refreshed", true);
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export async function logoutFromSSO() {
  sessionStorage.removeItem("p-keys");
  sessionStorage.removeItem("u-keys");
  sessionStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
  sessionStorage.removeItem(process.env.VUE_APP_REFRESH_TOKEN_KEY);
  const data = {
    post_logout_redirect_uri: mountUrl(process.env.VUE_APP_REDIRECT_URI_LOGOUT),
  };

  const params = new URLSearchParams(data);
  const url = new URL("logout", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);
  url.search = params.toString();

  window.location.href = url.href;
}

export async function auth(code, state) {
  const sessionState = sessionStorage.getItem("state");
  const sessionVerifier = sessionStorage.getItem("verifier");

  if (!code || !state || !sessionState || state !== sessionState) {
    throw new Error("Could not authenticate the given user information");
  }

  const url = new URL("token", process.env.VUE_APP_MICROSOFT_OAUTH2_URI);

  axios
    .post(
      url.href,
      {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: mountUrl(process.env.VUE_APP_REDIRECT_URI),
        client_id: process.env.VUE_APP_CLIENT_ID,
        code_verifier: sessionVerifier,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      sessionStorage.removeItem("state");
      sessionStorage.removeItem("verifier");

      sessionStorage.setItem(
        process.env.VUE_APP_TOKEN_KEY,
        response.data.access_token
      );
      sessionStorage.setItem(
        process.env.VUE_APP_REFRESH_TOKEN_KEY,
        response.data.refresh_token
      );
      window.location.href = Buffer.from(sessionState, "base64").toString(
        "ascii"
      );
    });
}
