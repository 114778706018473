import store from "@/store/index";
import { mapActions } from "vuex";
import UsuarioService from "@/services/portal/UsuarioService";
export default {
  methods: {
    ...mapActions([
      "ActionSetToken",
      "ActionSetUsuario",
      "ActionToggleStatusMenu",
    ]),
    mxTryLogin({ username, password, isTrocandoSenha = false }) {
      return new Promise((resolve, reject) => {
        UsuarioService.auth({ username, password, isTrocandoSenha })
          .then(({ data }) => {
            const { token, userId } = data;
            this.ActionSetToken(token);
            resolve(userId);
          })
          .catch((error) => reject(error));
      });
    },
    mxGetUserDetails(id, redirect = true) {
      return new Promise((resolve, reject) => {
        UsuarioService.searchUser({ id })
          .then(({ data }) => {
            this.ActionSetUsuario(data);
            if (redirect) {
              if (data.primeiroAcesso) {
                this.$router.push({ path: "/primeiro-acesso" });
              } else {
                this.ActionToggleStatusMenu(true);
                this.$router.push({ path: "/cadastros/buscar-empresas" });
              }
            }
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
    mxCheckIsLogged() {
      const tokenLocalStorage = window.sessionStorage.getItem("token");
      const tokenVuexStore = store.getters.getToken;

      if (tokenLocalStorage === tokenVuexStore) {
        return true;
      } else {
        this.$router.push({ path: "/login" });
        return null;
      }
    },
  },
};
