<template>
  <div class="mt-5" v-async="loading">
    <div class="row">
      <form-row-header text="Upload de Arquivos" />
    </div>
    <input-upload-field
      :modelProp="model"
      ref="inputUploadField"
      :disabled="
        !this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
      "
    />
    <div class="row mt-3">
      <tipo-anexo-field
        :modelProp="model"
        ref="tipoAnexoField"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <prazo-validade-anexo-field
        :modelProp="model"
        ref="prazoValidadeAnexoField"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <descricao-field
        v-if="model"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :modelProp="model"
        :isRequired="
          this.model.tipo && this.model.tipo.nome.toLowerCase() === 'outros'
        "
        ref="descricaoAnexoField"
      />
    </div>
    <div class="row mt-5 mb-2">
      <div class="col col-lg-auto ms-auto">
        <button
          @click="resetFields"
          class="btn btn-secondary"
          v-if="
            this.$can('CENTRAL_CADASTROS_EDITAR') ||
            this.$can('CENTRAL_CADASTROS_APROVAR') ||
            this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
        >
          Limpar
        </button>
        <button
          @click="save"
          class="btn btn-primary ms-3"
          v-if="
            this.$can('CENTRAL_CADASTROS_EDITAR') ||
            this.$can('CENTRAL_CADASTROS_APROVAR') ||
            this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
        >
          Adicionar
        </button>
      </div>
    </div>
    <div style="display: flex; align-items: center">
      <form-row-header text="Arquivos Enviados" />
      <help class="ms-2" boxAlign="right">
        Clique na linha para baixar o arquivo
      </help>
    </div>
    <table-arquivos-enviados
      @sortBy="sortBy"
      :anexosList="list"
      @deleteArquivo="remove"
      @downloadArquivo="downloadArquivo"
      :disabled="
        !this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
      "
    />
  </div>
</template>
<script>
import {
  cleanUpFields,
  mountFormDataForRequest,
  downloadFileFromResponseObject,
} from "@/utils/functionUtils";
import { formIsValid } from "@/utils/validators";
import CRUDTemplate from "@/components/CRUDTemplate";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import ArquivoModel from "@/models/empresa/ArquivoModel";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import ArquivoService from "@/services/portal/empresa/ArquivoService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { ERROR, MAX_FILE_SIZE, HTTP_RESPONSE } from "@/constants/strings";
import TipoAnexoField from "@/components/forms/fields/empresa/TipoAnexoField.vue";
import DescricaoField from "@/components/forms/fields/empresa/DescricaoField.vue";
import TableArquivosEnviados from "@/components/tables/TableArquivosEnviados.vue";
import InputUploadField from "@/components/forms/fields/empresa/InputUploadField.vue";
import PrazoValidadeAnexoField from "@/components/forms/fields/empresa/PrazoValidadeAnexoField.vue";
export default {
  extends: CRUDTemplate,
  data() {
    return {
      filter: {
        sort: ["empresaArquivoTipo.nome", "nome"],
      },
    };
  },

  mounted() {
    const { empresaId } = this.$parent;
    this.loading = EmpresaService.getById({ empresaId })
      .then(({ data }) => {
        this.requestEntity = "arquivo";
        this.service = ArquivoService;
        this.model = new ArquivoModel();
        this.empresa = new EmpresaModel(data);
        this.getByEmpresa();
        this.setHeaderText(this.empresa.razaoSocial);
      })
      .catch((error) => this.mxHandleRequestError(error, "arquivo"));
  },
  methods: {
    sortBy(emitted) {
      this.filter.sort = emitted;
      this.getByEmpresa();
    },
    getByEmpresa() {
      const { empresaId } = this.$parent;
      const { sort } = this.filter;
      this.loading = this.service
        .getByEmpresa({
          empresaId,
          sort,
        })
        .then(({ data }) => {
          this.list = data || new Array();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
    resetFields() {
      this.model.tipo = "";
      this.model.descricao = "";
      this.model.dataValidade = "";
      this.model.arquivo = null;
      document.getElementById("uploadField").classList.remove("file-attached");
      cleanUpFields(this);
    },
    arquivoSizeIsAllowed() {
      if (this.model.arquivo.size >= MAX_FILE_SIZE) {
        document
          .getElementById("uploadField")
          .classList.remove("file-attached");
        document.getElementById("uploadField").classList.add("is-invalid");
        return false;
      }
      return true;
    },
    arquivoNameSizeIsAllowed() {
      if (
        this.model.arquivo &&
        this.model.arquivo.name &&
        this.model.arquivo.name.length >= 100
      ) {
        document
          .getElementById("uploadField")
          .classList.remove("file-attached");
        document.getElementById("uploadField").classList.add("is-invalid");
        return false;
      }
      return true;
    },
    downloadArquivo(arquivo) {
      const { id } = arquivo;
      const { empresaId } = this.$parent;
      this.loading = ArquivoService.downloadArquivo({ id }, empresaId)
        .then((response) =>
          downloadFileFromResponseObject(response, arquivo.getFileName())
        )
        .catch((error) => this.mxHandleRequestError(error, "arquivo"));
    },
    clearArquivoSelection() {
      document.getElementById("uploadField").classList.remove("file-attached");
    },
    save() {
      if (formIsValid(this)) {
        if (this.arquivoSizeIsAllowed()) {
          if (this.arquivoNameSizeIsAllowed()) {
            this.mxResetErrorPendencies();
            const { id } = this.empresa;
            const modelData = this.model.getData()
            if (!modelData.descricao) {
              modelData.descricao = ""
            }
            this.loading = ArquivoService.create(
              mountFormDataForRequest(modelData),
              id
            )
              .then(() => {
                this.mxToggleToast(HTTP_RESPONSE.ARQUIVO.SUCCESS.CREATE);
                this.getByEmpresa();
              })
              .catch((error) => this.mxHandleRequestError(error, "arquivo"));
            this.clearArquivoSelection();
            this.resetFields();
          } else {
            this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_NAME);
          }
        } else {
          this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_MB);
        }
      }
    },
  },
  components: {
    FormRowHeader,
    DescricaoField,
    TipoAnexoField,
    InputUploadField,
    TableArquivosEnviados,
    PrazoValidadeAnexoField,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
