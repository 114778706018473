<template>
  <div class="page" v-async="loading">
    <div class="container row justify-content-center">
      <div class="col col-lg-8 box-content">
        <div class="mb-3 mt-3 row justify-content-center">
          <div
            class="
              col
              d-flex
              flex-column
              align-items-center
              justify-content-between
            "
          >
            <img
              style="max-width: 150px"
              src="@/assets/images/logo-matrix-2.png"
              class="rounded w-25 mx-auto d-block img-fluid"
              alt="Logotipo Matrix Energia"
            />
            <span class="mt-2 fw-bold fs-5">Central de Cadastros</span>
          </div>
        </div>
        <form v-if="updatingPassword" class="needs-validation" novalidate>
          <div class="mb-4 row justify-content-center">
            <div
              style="text-align: center"
              class="infoTextCard col col-lg-10 col-md-10 col-sm-10"
            >
              Atualize sua senha para prosseguir.
            </div>
          </div>
          <div class="mb-3 mt-4 row justify-content-center">
            <div class="col col-lg-10 col-md-10 col-sm-10">
              <div class="input-group">
                <label for="passwordlField">
                  Senha temporária <span class="required-markup">*</span>
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.password.$error,
                    }"
                    id="passwordlField"
                    v-model.trim="$v.password.$model"
                  />
                  <div class="form-text">
                    <span
                      v-if="!$v.password.$error || $v.password.$model === ''"
                      >Preencha com sua senha atual.</span
                    >
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="mb-3 mt-4 row justify-content-center">
            <div class="col col-lg-10 col-md-10 col-sm-10">
              <div class="input-group">
                <label for="newPasswordlField">
                  Nova senha <span class="required-markup">*</span>
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.newPassword.$error,
                    }"
                    id="newPasswordlField"
                    v-model.trim="$v.newPassword.$model"
                    maxlength="10"
                  />
                  <div class="form-text">
                    <span
                      style="color: red"
                      class="error"
                      v-if="
                        !$v.newPassword.minLength && $v.newPassword.$model != ''
                      "
                      >{{ INVALID_FIELD_MSG.PASSWORD_MIN_LEN }}</span
                    >
                    <span
                      v-if="
                        !$v.newPassword.$error || $v.newPassword.$model === ''
                      "
                      >Preencha com sua nova senha.</span
                    >
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="mb-3 row justify-content-center mt-4">
            <div class="col col-lg-10 col-md-10 col-sm-10">
              <div class="input-group">
                <label for="passConfirm">
                  Confirmação da nova senha
                  <span class="required-markup">*</span>
                  <input
                    class="form-control"
                    type="password"
                    :class="{
                      'is-invalid': $v.repeatPassword.$error,
                    }"
                    id="passConfirm"
                    v-model.trim="$v.repeatPassword.$model"
                    maxlength="10"
                  />
                  <div class="form-text">
                    <span v-if="!$v.repeatPassword.$error"
                      >Repita aqui a nova senha.</span
                    >
                    <span
                      style="color: red"
                      class="error"
                      v-if="
                        !$v.repeatPassword.sameAsPassword &&
                        $v.repeatPassword.$model != ''
                      "
                      >Senhas precisam ser idênticas.</span
                    >
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <div
              class="row justify-content-center col-lg-4 col-md-4 col-sm-8 mb-4"
            >
              <button
                @click.prevent="changePassword"
                class="btn btn-primary btn-sm"
              >
                Alterar Senha
              </button>
            </div>
          </div>
        </form>
        <div v-else>
          <div class="mb-4 row d-flex flex-column align-items-center">
            <div class="text-center infoTextCard">
              Senha atualizada com sucesso!
            </div>
            <div
              v-if="
                userHasBillingPermissions ||
                userHasCentralPermissions ||
                userHasEnviadorPermissions
              "
              class="text-center mt-3 infoTextCard"
            >
              Selecione a aplicação que deseja acessar:
            </div>
          </div>
          <div class="mb-4 application-options">
            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('CENTRAL')"
              v-if="userHasCentralPermissions"
            >
              Central de Cadastros
            </button>

            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('ENVIADOR')"
              v-if="userHasEnviadorPermissions"
            >
              Enviador de Propostas
            </button>

            <button
              class="btn btn-primary btn-sm btn-redirect"
              @click="redirectTo('BILLING')"
              v-if="userHasBillingPermissions"
            >
              Billing
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import UsuarioService from "@/services/portal/UsuarioService";
import { INVALID_FIELD_MSG } from "@/constants/form.constants";
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { userHasPermissions } from "@/utils/permissions";

export default {
  data() {
    return {
      password: "",
      newPassword: "",
      showLinks: true,
      INVALID_FIELD_MSG,
      repeatPassword: "",
      loading: undefined,
      updatingPassword: true,
    };
  },
  computed: {
    ...mapGetters(["getUsuario"]),
    userHasCentralPermissions() {
      const permissaoCentral = this.getUsuario.role.permissoes.find(
        (permissao) => permissao.tipo === "CENTRAL_CADASTROS"
      );

      return (
        permissaoCentral &&
        [
          "ACESSAR_CENTRAL_CADASTROS",
          "CENTRAL_CADASTROS_CONSULTAR",
          "CENTRAL_CADASTROS_EDITAR",
          "CENTRAL_CADASTROS_APROVAR",
          "CENTRAL_CADASTROS_PARAMETRIZAR",
          "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
          "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR",
          "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
          "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR",
        ].includes(permissaoCentral.permissao)
      );
    },
    userHasEnviadorPermissions() {
      const permissaoEnviador = this.getUsuario.role.permissoes.find(
        (permissao) => permissao.tipo === "GERACAO_PROPOSTAS"
      );

      return (
        permissaoEnviador &&
        ["GERACAO_PROPOSTAS_EDITAR", "GERACAO_PROPOSTAS_PARAMETRIZAR"].includes(
          permissaoEnviador.permissao
        )
      );
    },
    userHasBillingPermissions() {
      const permissoesBilling = this.getUsuario.role.permissoes.filter(
        (permissao) => permissao.tipo === "BILLING"
      );

      return (
        permissoesBilling.length > 0 &&
        [
          "ACESSAR_BILLING",
          "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
          "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
          "CONFIGURAR_PROINFA_VISUALIZAR",
          "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
          "VISUALIZAR_FATURAMENTO",
          "REALIZAR_FATURAMENTO",
          "IMPORTACAO_LOTE_HABILITADO",
          "CONTRATOS_NAO_IMPORTADOS_HABILITADO",
          "FECHAR_FATURAMENTO_HABILITADO",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N1",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N2",
          "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N3",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2",
          "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3",
          "EXPORTADOR_MONTANTES_VISUALIZAR",
          "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
          "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
          "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR",
          "ASSOCIAR_POS_VENDA_VISUALIZAR",
          "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
          "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
          "ALCADAS_APROVACAO_VISUALIZAR",
          "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
          "CURTO_PRAZO_VISUALIZAR",
          "CURTO_PRAZO_VISUALIZAR_EDITAR",
          "ROTINA_IMPORTACAO_VISUALIZAR",
          "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
          "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
          "BUSCAR_APURACOES_CCEE",
        ].findIndex((permissao) =>
          permissoesBilling.find(
            (permissaoBilling) => permissaoBilling.permissao == permissao
          )
        ) > -1
      );
    },
  },
  validations: {
    password: {
      required,
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(10),
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  mixins: [MxUserUtils],
  methods: {
    redirectTo(application = "CENTRAL") {
      this.ActionSetToken(null);
      if (application === "BILLING") {
        window.location.href = process.env.VUE_APP_ROOT_BILLING_URL;
      } else if (application === "ENVIADOR") {
        window.location.href = process.env.VUE_APP_ROOT_ENVIADOR_URL;
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    changePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = UsuarioService.changePassword({
          senhaAntiga: this.password,
          senhaNova: this.newPassword,
        })
          .then(() => {
            if (
              this.userHasCentralPermissions &&
              !this.userHasEnviadorPermissions &&
              !this.userHasBillingPermissions
            ) {
              this.redirectTo("CENTRAL");
            } else if (
              !this.userHasCentralPermissions &&
              this.userHasEnviadorPermissions &&
              !this.userHasBillingPermissions
            ) {
              this.redirectTo("ENVIADOR");
            } else if (
              !this.userHasCentralPermissions &&
              !this.userHasEnviadorPermissions &&
              this.userHasBillingPermissions
            ) {
              this.redirectTo("BILLING");
            } else {
              this.showLinks = true;
              this.updatingPassword = false;
            }
          })
          .catch((error) =>
            this.mxHandleRequestError(error, "CHANGE_NEW_PASSWORD")
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
.page {
  font-size: 0.8em;
}

.icon {
  color: white !important;
}

.page {
  @include fullScreen;
  @include flex-centered;
  flex-direction: column;
  background-color: $primary--black;
}

.infoTextCard {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 24px;
}
.text-confirmed {
  font-style: italic;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 20px;
  color: #2ed47a;
}

.box-content {
  border-radius: 4px;
  background-color: $primary--white;
  padding: 0px !important;
}
.card-login-footer {
  width: 100%;
  padding: 10px 0;
  background: #e8e8e8;
  border-radius: 0px 0px 5px 5px;
  color: #ec6723;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application-options {
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  align-items: center;
  color: $primary--orange;
  justify-content: space-evenly;

  div {
    cursor: pointer;
  }
}

.application-options div:hover {
  text-decoration: underline;
}
.btn-redirect {
  min-width: 150px;
}
</style>
