const CONFIRMATION = {
  CONTATO: {
    DELETE: "Deseja deletar este contato?",
  },
  ARQUIVO: {
    DELETE: "Deseja deletar este arquivo?",
  },
  PONTO_MEDICAO: {
    DELETE: "Deseja deletar este ponto de medição?",
  },
  DADO_BANCARIO: {
    DELETE: "Deseja deletar esta conta?",
    INACTIVE_PRINCIPAL: "Esta empresa já possui uma conta principal vinculada. Ao salvar, ela será substituída. Deseja prosseguir ?"
  },
  CCEE: {
    DELETE: "Deseja deletar este Perfil de Agente?",
  },
  EMPRESA: {
    UPDATE: "Você deseja seguir com a aprovação do cadastro?",
  },
  GESTOR: {
    UPDATE: "Deseja trocar o gestor?",
    UNBIND: "Deseja desassociar o Gestor vinculado à Empresa?",
    SAVE_WO_ASSOCIATION:
      "Esta empresa não terá nenhum Gestor vinculado, deseja prosseguir?",
  },
  POS_VENDAS: {
    UPDATE: "Deseja trocar o agente pós-venda?",
    UNBIND: "Deseja desassociar o Agente Pós-Venda vinculado à Empresa?",
    SAVE_WO_ASSOCIATION:
      "Esta empresa não terá nenhum Agente Pós-Venda vinculado, deseja prosseguir?",
  },
  GRUPO_ECONOMICO: {
    DELETE: "Deseja deletar este Grupo Econômico?",
    UPDATE: "Deseja atualizar este Grupo Econômico?",
    DISASSOCIATE: "Deseja retirar esta Empresa do Grupo Econômico?",
  },
  APP: {
    LOGOUT: "Deseja sair da Central de Cadastros?",
  },
};

const MAX_FILE_SIZE = 52428800;
const ERROR = {
  MAX_FILE_SIZE_MB: `Arquivo inválido, tamanho excede o limite de ${parseInt(
    MAX_FILE_SIZE / 1000 / 1000
  )} mb`,
  MAX_FILE_SIZE_NAME: "Nome do arquivo ultrapassa o permitido: 100 caracteres",
  NO_CONTATOS_ADDED: "Nenhum contato adicionado",
  CONTATO_DUPLICATED_EMAIL:
    "Não foi possível adicionar o contato, e-mail já existente",
  CONTATO_DUPLICATED_CPF:
    "Não foi possível adicionar o contato, cpf já existente",
  NO_GESTOR_ADDED: "Nenhum Gestor selecionado",
};

const PENDENCIES = {
  SEND_DEMONSTRATIVOS_FATURAMENTO_OFF:
    '<strong>PENDÊNCIA</strong>: O envio automático de demonstrativos de faturamento está desabilitado. Acesse a aba <strong>"Pós-Vendas"</strong> e ative a opção <strong>"Enviar por e-mail"</strong>.',
  NOT_CONTATOS_APROVA:
    'Para ativar o envio automático de demonstrativos de faturamento, acesse a aba “Contatos” e configure ao menos um contato com permissão "Aprova" na opção "Demonstrativo de Faturamento".',
  IS_INVALID_YELLOW:
    "Os campos pendentes não obrigatórios estão destacados em <strong>amarelo<strong>.",
};

const HTTP_RESPONSE = {
  GENERIC: {
    400: "Ocorreu um erro ao processar sua solicitação, verifique as informações enviadas",
    403: "Sua sessão expirou, faça novamente o login para continuar",
    404: "Ocorreu um erro ao processar sua solicitação: recurso não encontrado",
    409: "Ocorreu um erro ao processar sua solicitação: entidade já cadastrada",
    500: "Ocorreu um erro ao processar sua solicitação: serviço indisponível",
  },
  CEP: {
    ERROR: {
      404: "O CEP inserido não foi encontrado na base dos Correios.",
    },
  },
  EMPRESA: {
    SUCCESS: {
      CREATE: "Empresa criada com sucesso",
      UPDATE: "Empresa atualizada com sucesso",
    },
    ERROR: {
      409: "Não foi possível cadastrar a empresa: CNPJ já cadastrado",
      },
  },
  CONTATO: {
    SUCCESS: {
      CREATE: "Contato criado com sucesso",
      UPDATE: "Contato atualizado com sucesso",
      DELETE: "Contato deletado com sucesso",
    },
    ERROR: {
      409: "Não foi possível cadastrar o contato: e-mail já cadastrado",
    },
  },
  DADO_BANCARIO: {
    SUCCESS: {
      CREATE: "Dado bancário criado com sucesso",
      UPDATE: "Dado bancário atualizado com sucesso",
      DELETE: "Dado bancário deletado com sucesso",
    },
    ERROR: {},
  },
  CCEE: {
    SUCCESS: {
      CREATE: "Perfil CCEE criado com sucesso",
      UPDATE: "Perfil CCEE atualizado com sucesso",
      DELETE: "Perfil CCEE deletado com sucesso",
    },
    ERROR: {
      409: "O Perfil do Agente inserido já existe.",
    },
  },
  GRUPO_ECONOMICO: {
    SUCCESS: {
      DELETE: "Grupo econômico excluído com sucesso",
      CREATE: "Grupo econômico cadastrado com sucesso",
      UPDATE: "Grupo econômico atualizado com sucesso",
      DISASSOCIATE: "Empresa retirada do Grupo Econômico com sucesso",
    },
    ERROR: {
      409: "Não foi possível cadastrar o Grupo Econômico: Empresa já associada a outro grupo",
    },
  },
  NOME_GRUPO_ECONOMICO: {
    ERROR: {
      409: "Não foi possível atualizar o Grupo Econômico: Já existe outro Grupo com o nome informado",
    },
  },
  NOME_GRUPO_ACESSO: {
    ERROR: {
      409: "Não foi possível atualizar o Grupo de Acesso, Já existe outro Grupo com o nome informado",
    },
  },
  GESTOR: {
    SUCCESS: {
      UPDATE: "Gestor atualizado com sucesso",
    },
    ERROR: {},
  },
  EMPRESA_CONFIGURACOES: {
    SUCCESS: {
      UPDATE: "Configurações da empresa atualizadas com sucesso",
    },
    ERROR: {},
  },
  PONTO_MEDICAO: {
    SUCCESS: {
      CREATE: "Ponto de medição criado com sucesso",
      UPDATE: "Ponto de medição atualizado com sucesso",
      DELETE: "Ponto de medição deletado com sucesso",
    },
    ERROR: {
      409: ({ data }) => `Este Ponto de Medição já foi cadastrado para o CNPJ ${data.keys.cnpj}`,
    },
  },
  EST_RATEIO: {
    SUCCESS: {
      CREATE: "Informação criado com sucesso",
      UPDATE: "Informações atualizadas com sucesso",
      DELETE: "Informações deletadas com sucesso",
    },
    ERROR: {
      400: "Ocorreu um erro ao processar sua solicitação, verifique as informações enviadas",
      403: "Sua sessão expirou, faça novamente o login para continuar",
      404: "Ocorreu um erro ao processar sua solicitação: recurso não encontrado",
      409: "Ocorreu um erro ao processar sua solicitação: entidade já cadastrada",
      422: 'A empresa referenciada em "CNPJ da Empresa Mãe" é inválida: a empresa também possui uma Matriz',
      500: "Ocorreu um erro ao processar sua solicitação: serviço indisponível",
    },
  },
  ARQUIVO: {
    SUCCESS: {
      CREATE: "Arquivo criado com sucesso",
      DELETE: "Arquivo deletado com sucesso",
    },
    ERROR: {},
  },
  USUARIO: {
    SUCCESS: {
      CREATE: "Usuário criado com sucesso",
      UPDATE: "Usuário atualizado com sucesso",
      DELETE: "Usuário deletado com sucesso",
    },
    ERROR: {
      401: "Login inválido! Você não possui permissões para acessar a Central de Cadastros.",
      403: "Usuário e/ou senha inválidos",
      409: "Não foi possível realizar o cadastro: usuário já está cadastrado",
    },
  },
  PARAMETRO: {
    SUCCESS: {
      CREATE: "Parâmetro cadastrado com sucesso",
      UPDATE: "Parâmetro atualizado com sucesso",
      DELETE: "Parâmetro deletado com sucesso",
    },
    ERROR: {
      409: "Não foi possível cadastrar o parâmetro: valor já cadastrado",
      422: "Não foi possível deletar o parâmetro: valor sendo utilizado por outras empresas",
    },
  },
  GRUPO_ACESSO: {
    SUCCESS: {
      CREATE: "Grupo criado com sucesso",
      UPDATE: "Grupo atualizado com sucesso",
      DELETE: "Grupo deletado com sucesso",
    },
    ERROR: {
      409: "Não foi possível realizar o cadastro: grupo de acesso já está cadastrado",
    },
  },
  CHANGE_NEW_PASSWORD: {
    ERROR: {
      403: "Não foi possível atualizar a senha: Senha atual incorreta",
      422: "Não foi possível atualizar a senha: A nova senha não pode ser igual à senha antiga",
    },
    FAILURE:
      "Não foi possível atualizar a senha: Preencha os campos corretamente para prosseguir",
    SUCCESS: {
      CREATE: "Dados salvos com sucesso",
    },
  },
  AGENDAMENTO_TAREFA: {
    ERROR: {
      400: "Não foi possível cadastrar o parâmetro: houve um erro na formatação",
      409: "Não foi possível cadastrar o parâmetro: email já cadastrado",
      500: "Ocorreu um erro ao processar sua solicitação: serviço indisponível",
    },
  },
};

const TOOLTIP_MESSAGES = {
  EMPRESA_HAS_FILIAIS:
    'Os campos "Boleta Mãe" e "CNPJ da Empresa Mãe" estão desabilitados porque essa empresa está vinculada como Empresa Mãe de outra empresa.',
};

export {
  ERROR,
  CONFIRMATION,
  HTTP_RESPONSE,
  MAX_FILE_SIZE,
  PENDENCIES,
  TOOLTIP_MESSAGES,
};
