<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="demandaContratadaPontaField">
        Demanda Contratada Ponta
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="validar"
          @blur="repararString"
          :maxlength="FIELD_MAX_LENGTH"
          :disabled="disabled"
          :class="{
            'recent-updated': recentUpdatedField,
            'is-invalid': $v.model.content.$error,
          }"
          id="demandaContratadaPontaField"
          v-model.trim="$v.model.content.$model"
          class="form-control mt-2"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { getDecimalSeparatorIndex } from "@/utils/functionUtils";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "demandaContratadaPonta",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Campo Demanda Contratada Ponta inválido"
        );
        return true;
      }
      return false;
    },
    validar() {
      const text = this.model.content;
      const regex = new RegExp(/^[0-9.,]+$/);
      const indexOf = getDecimalSeparatorIndex(text);
      if (
        !regex.test(text) ||
        text.split(",").length + text.split(".").length > 3
      ) {
        this.model.content = text.slice(0, -1);
      } else {
        if (indexOf) {
          if (
            (text.toString().includes(".") || text.toString().includes(",")) &&
            text.toString().substring(indexOf + 1).length > 3
          ) {
            this.model.content = text.slice(0, -1);
          }
        }
      }
    },
    repararString() {
      const text = this.model.content;
      const indexOf = getDecimalSeparatorIndex(text);
      if (indexOf === null) return;
      if (indexOf >= 0) {
        this.model.content += "0".repeat(
          (text.toString().substring(indexOf + 1).length - 3) * -1
        );
      } else {
        this.model.content += ",000";
      }
    },
  },
  computed: {
    FIELD_MAX_LENGTH() {
      if (
        this.model.content &&
        this.model.content.toString() &&
        (this.model.content.toString().includes(".") ||
          this.model.content.toString().includes(","))
      ) {
        return 15;
      }
      return 11;
    },
  },
  watch: {
    "$parent.shouldRepararString": {
      handler() {
        this.repararString();
      }
    }
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}
</style>